import React from 'react'

import BaseLayout from '../../layouts/BaseLayout'
import DocumentsCover from '../../ge/action-section__search.png'

import { DOCUMENTS } from './Documents.constants'

const DocumentsPage = () => {
  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid">
          <div className="documents-section">
            <h1 className="documents-section__title">Документы</h1>
            <picture>
              <img className="documents-section__cover" alt="Main document cover" src={DocumentsCover} />
            </picture>
            <div className="documents-section__content row">
              {DOCUMENTS.map(item => (
                <div key={item.id} className="documents-section__item col-12 col-md-4">
                  <div className="documents-section__item-icon">
                    <img src={item.img} alt={item.alt} />
                  </div>
                  <p className="documents-section__item-title">{item.title}</p>
                  <a href={`/docs/${item.download}`} download className="documents-section__item-link">Скачать</a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default DocumentsPage
