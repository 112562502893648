import React, { useState, useEffect } from 'react'
import { Formik } from 'formik/dist/index'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { TextField, CheckBoxField } from '../../components/Input'
import { Button } from '../../components/Button'
import iconVk from '../../ge/social-vk-hover.svg'
import iconFb from '../../ge/social-fb-hover.svg'
// import iconG from '../../ge/soc-gl-auth.svg' WIP
import { closeAuthorizationForm, openChangePasswordForm, openRegistrationForm } from '../BaseLayout.actions'
import { FB_AUTH_URL, VK_AUTH_URL } from '../../constants/Api.constants'

import { AUTH_FORM_INITIAL_VALUES, AUTH_FORM_FIELDS, AUTH_VALIDATION } from './Authorization.constants'
import { userAuthAction } from './Authorization.actions'

const AuthorizationForm = ({
  closeAuthorizationForm,
  openRegistrationForm,
  openChangePasswordForm,
  userAuthAction,
  isFetching
}) => {
  const [ isFormMount, setFormMount ] = useState(false)

  const showRegistrationFrom = () => {
    openRegistrationForm()
  }

  const formClassName = classNames({
    'authorization-form': true,
    'authorization-form--show': isFormMount
  })

  useEffect(() => {
    setTimeout(() => setFormMount(true), 100)
  }, [])

  const onCloseForm = () => {
    setFormMount(false)
    setTimeout(closeAuthorizationForm, 300)
  }

  const handlerVkAuth = () => {
    window.location.replace(VK_AUTH_URL)
  }

  const handlerFbAuth = () => {
    window.location.replace(FB_AUTH_URL)
  }

  return (
    <div className={formClassName}>
      <div
        className="authorization-form__mask"
        onClick={() => onCloseForm()}
      />
      <div className="authorization-form__content">
        <div className="authorization-form__content-wrap text-center">
          <i className="icon icon--close authorization-form__close" onClick={() => onCloseForm()} />
          <h5 className="authorization-form__title">Вход</h5>
          <Formik
            initialValues={AUTH_FORM_INITIAL_VALUES}
            validationSchema={AUTH_VALIDATION}
            onSubmit={(values) => userAuthAction(values)}
          >
            {(f) => (
              <form
                className="authorization-form__block"
                onSubmit={f.handleSubmit}
              >
                <TextField
                  medium
                  tabIndex="1"
                  name={AUTH_FORM_FIELDS.EMAIL}
                  label="E-mail"
                  value={f.values.email}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  error={f.errors.email && f.touched.email && f.errors.email}
                  className="authorization-form__input"
                />
                <TextField
                  isForgotVisible
                  medium
                  tabIndex="2"
                  name={AUTH_FORM_FIELDS.PASSWORD}
                  type="password"
                  label="Пароль"
                  value={f.values.password}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  onChangePassword={openChangePasswordForm}
                  className="authorization-form__input"
                  error={f.errors.password && f.touched.password && f.errors.password}
                />
                <CheckBoxField
                  id="auth_checkbox"
                  name={AUTH_FORM_FIELDS.CHECKBOX}
                  label="Запомнить меня"
                  onChange={f.handleChange}
                  remember
                  cnLabel="authorization-form__consent-label"
                />
                <Button
                  type="submit"
                  medium
                  tabIndex="3"
                  label="Войти"
                  width="100%"
                  className="authorization-form__button"
                  color={(!f.isValid || isFetching) ? 'gray' : 'orange'}
                  disabled={!f.isValid || isFetching}
                />
              </form>
            )}
          </Formik>
          <div className="text-center authorization-form__social">
            <p className="authorization-form__social-title">Войти с помощью</p>
            <div className="social-buttons">
              <Button
                label=""
                color="orange"
                nobg
                noborder
                width={50}
                icon={iconVk}
                onClick={handlerVkAuth}
              />
              <Button
                label=""
                color="orange"
                nobg
                noborder
                width={50}
                icon={iconFb}
                onClick={handlerFbAuth}
              />
              {/* WIP: Google auth */}
              {/* <Button
                label=""
                color="orange"
                nobg
                noborder
                width={50}
                icon={iconG}
              /> */}
            </div>
          </div>
          <h5 className="text-center authorization-form__title">Первый раз тут?</h5>
          <Button
            className="authorization-form__button-registration"
            label="Регистрация"
            color="orange"
            width="100%"
            nobg
            medium
            onClick={() => showRegistrationFrom()}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ baseLayoutReducer: { showAuthorizationForm }, auth: { isFetching } }) => ({
  showAuthorizationForm, isFetching
})

const mapDispatchToProps = (dispatch) => ({
  closeAuthorizationForm: () => dispatch(closeAuthorizationForm()),
  openRegistrationForm: () => dispatch(openRegistrationForm()),
  openChangePasswordForm: () => dispatch(openChangePasswordForm()),
  userAuthAction: (params) => dispatch(userAuthAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationForm)
