import { toast } from 'react-toastify'

import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'
import { TOAST_OPTIONS } from '../Index/Index.constants'

const CREATE_PROJECT = loadReduxTypes('CREATE_PROJECT')
const UPLOAD_FILES = loadReduxTypes('UPLOAD_FILES')
const REMOVE_FILE = loadReduxTypes('REMOVE_FILE')
const CATEGORY = loadReduxTypes('CATEGORY')

function createProjectAction (params) {
  return function action (dispatch) {
    dispatch({ type: CREATE_PROJECT.REQUEST })

    const {
      member_private_name,
      member_private_phone,
      member_private_email,
      name,
      initiator_name,
      initiator_description,
      initiator_legal_address,
      initiator_physical_address,
      initiator_web_site,
      short_description_idea,
      short_description_unique,
      short_description_features,
      project_goal_problems,
      project_goal_current_state_of_affairs,
      project_goal_desired_result,
      project_goal_already_done,
      project_goal_required_steps,
      project_goal_required_resources,
      target_audience,
      target_audience_benefits,
      member_citation,
      member_name,
      member_position,
      fund_sources,
      personal_fund_sources,
      bonuses,
      video,
      projectFiles
    } = params

    const data = {
      member_private_name,
      member_private_phone: member_private_phone.slice(2).replaceAll('-', ''),
      member_private_email,
      name,
      initiator_name,
      initiator_description,
      initiator_legal_address,
      initiator_physical_address,
      initiator_web_site,
      organization_logo: projectFiles.organization_logo[0],
      short_description_idea,
      short_description_unique,
      short_description_features,
      project_goal_problems,
      project_goal_current_state_of_affairs,
      project_goal_desired_result,
      project_goal_already_done,
      project_goal_required_steps,
      project_goal_required_resources,
      target_audience,
      target_audience_benefits,
      member_citation,
      member_name,
      member_position,
      member_photo: projectFiles.member_photo[0],
      fund_sources,
      personal_fund_sources,
      bonuses,
      photos: projectFiles.photos,
      video,
      estimate_document: projectFiles.estimate_document[0],
      initiator_social_links: [
        params.first_social_link,
        params.second_social_link,
        params.third_social_link
      ]
    }

    agent.POST('/project-brief', data)
      .then(() => {
        dispatch({
          type: CREATE_PROJECT.SUCCESS,
          payload: {
            success: true
          }
        })
        params.push('/')
        toast.info('Проект успешно создан и отправлен на модерацию.', TOAST_OPTIONS)
      })

      .catch((error) => {
        dispatch({
          type: CREATE_PROJECT.FAILURE,
          payload: {
            error
          }
        })
        toast.info('Возникла ошибка при создании проекта', TOAST_OPTIONS)
      })
  }
}

function createCategoryAction (name) {
  return function action (dispatch) {
    dispatch({ type: CATEGORY.REQUEST })

    agent.POST('/request_category', { name })
      .then(() => {
        dispatch({ type: CATEGORY.SUCCESS })
      })

      .catch(error => {
        dispatch({
          type: CATEGORY.FAILURE,
          payload: error
        })
      })
  }
}

function uploadFileAction (params, fieldName) {
  return function action (dispatch) {
    dispatch({ type: UPLOAD_FILES.REQUEST })

    agent.POST('/upload', { file: params })
      .then((response) => {
        const { data: { file_id: fileId } } = response.data

        dispatch({
          type: UPLOAD_FILES.SUCCESS,
          payload: { fileId, fieldName }
        })
      })

      .catch(error => {
        dispatch({
          type: UPLOAD_FILES.FAILURE,
          payload: error
        })
      })
  }
}

function removeFileAction (removedFileIndex, fieldName) {
  return function action (dispatch) {
    dispatch({ type: REMOVE_FILE.REQUEST, payload: { fileIndex: removedFileIndex, fieldName } })
  }
}

export {
  createProjectAction,
  uploadFileAction,
  removeFileAction,
  createCategoryAction
}
