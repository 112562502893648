import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import CookieBanner from '../components/CookieBanner/CookieBanner'
import {
  openConfirmPasswordForm
} from '../layouts/BaseLayout.actions'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ScrollButton from '../components/ScrollButton'
import MobileMenu from '../components/MobileMenu'

import AuthorizationForm from './authorization/AuthorizationForm'
import RegistrationForm from './registration/BaseLayout.RegistrationForm'
import ChangePasswordForm from './BaseLayout.ChangePasswordForm'
import ConfirmPasswordForm from './BaseLayout.ConfirmPasswordForm'
import RegistrationSuccess from './registration/BaseLayout.RegistrationSuccess'

const BaseLayout = ({ children }) => {
  const match = useRouteMatch()
  const dispatch = useDispatch()

  const { baseLayoutReducer: {
    showAuthorizationForm,
    showRegistrationFrom,
    showChangePasswordFrom,
    showConfirmPasswordFrom,
    showRegistrationSuccess,
    isShowMobileMenu
  } } = useSelector(state => state)

  useEffect(() => {
    if (match.path === '/reset_password') {
      dispatch(openConfirmPasswordForm())
    }
  }, [ match.path, dispatch ])

  useEffect(() => {
    const isFormVisible = showAuthorizationForm ||
      isShowMobileMenu ||
      showRegistrationFrom ||
      showChangePasswordFrom ||
      showConfirmPasswordFrom

    // TODO: Change this way to disable scroll for BaseLayout (body-scroll-lock for example)
    if (document.documentElement.clientWidth <= 768 && isFormVisible) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [
    showAuthorizationForm,
    showRegistrationFrom,
    isShowMobileMenu,
    showChangePasswordFrom,
    showConfirmPasswordFrom
  ])

  const isMainPage = match.path === '/' && match.isExact
  const isAboutPage = match.path === '/about'
  const isSchoolPage = match.path === '/school/:id'
  const isResetPassword = match.path === '/reset_password'
  const isNewsPage = match.path === '/news'
  const isProjectInfo = match.path === '/project-info'
  const isPartnerInfo = match.path === '/partner-info'

  const contentClassName = classNames({
    'content': true,
    'content--blur': showAuthorizationForm ||
      showRegistrationFrom ||
      showChangePasswordFrom ||
      showConfirmPasswordFrom ||
      showRegistrationSuccess
  })

  const mainClassName = classNames({
    'main': !isNewsPage,
    'main-news-container': isNewsPage,
    'main--main-page': isMainPage || isSchoolPage || isResetPassword,
    'main--about-page': isAboutPage,
    'main--project-info': isProjectInfo || isPartnerInfo
  })

  return (
    <React.Fragment>
      <CookieBanner />
      { showAuthorizationForm && <AuthorizationForm /> }
      { showRegistrationFrom && <RegistrationForm /> }
      { showChangePasswordFrom && <ChangePasswordForm /> }
      { showConfirmPasswordFrom && <ConfirmPasswordForm />}
      { showRegistrationSuccess && <RegistrationSuccess /> }

      {isShowMobileMenu && <MobileMenu />}
      <div id="content" className={contentClassName}>
        <Header />
        <main className={mainClassName}>
          { children }
        </main>
        <Footer />
        <ScrollButton />
      </div>
    </React.Fragment>
  )
}

export default BaseLayout
