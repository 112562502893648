import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { fCash } from '../../helpers/formatters'
import { getPluralizeWord } from '../../pages/utils/getObjectFields'
import CheckBoxIcon from '../../ge/checkbox.svg'
import { STAGE_TYPES } from '../../pages/Project/constants'
import { getPercentageByStage } from '../../pages/utils/projectStages'

const ProjectProgress = ({ partners, projectData, stageType }) => {
  const { collected, goal, balance, stage } = projectData
  const totalPartnersString = `${getPluralizeWord(partners, [ 'Партнер', 'Партнера', 'Партнеров' ])}`

  const progressPercentage = getPercentageByStage(stageType, collected, goal, stage)

  const stageClasses = classNames('progress-bar__stage', {
    'progress-bar__stage--final': stageType === STAGE_TYPES.INTEGRATION
  })

  const wrapperClasses = classNames('progress-bar__wrapper', {
    'progress-bar__wrapper--hidden': stageType === STAGE_TYPES.FINISHED,
    'progress-bar__wrapper--finally': stageType === STAGE_TYPES.INTEGRATION
  })

  return (
    <div className="project-progress">
      <div className="project-progress__details details">
        {stageType === STAGE_TYPES.FUNDRAISING &&
        <React.Fragment>
          <div className="details__block">
            <div className="details__count">{partners}</div>
            <div className="details__field">{totalPartnersString}</div>
          </div>

          <div className="details__block">
            <div className="details__count">{fCash(balance)}</div>
            <div className="details__field">Осталось собрать</div>
          </div>

          <div className="details__block">
            <div className="details__count">{fCash(goal)}</div>
            <div className="details__field">Цель</div>
          </div>
        </React.Fragment>
        }

        {stageType === STAGE_TYPES.INTEGRATION &&
        <React.Fragment>
          <div className="details__block">
            <div className="details__count">{partners}</div>
            <div className="details__field">{totalPartnersString}</div>
          </div>

          <div className="details__block">
            <div className="details__count">Реализация проекта</div>
          </div>

          <div className="details__block">
            <div className="details__count">{fCash(collected)}</div>
            <div className="details__field">Собрано</div>
          </div>
        </React.Fragment>
        }

        {stageType === STAGE_TYPES.FINISHED &&
        <React.Fragment>
          <div className="details__block">
            <div className="details__count">
              <div className="details__icon-block">
                <img src={CheckBoxIcon} alt="" /> Проект реализован
              </div>
            </div>
          </div>

          <div className="details__block">
            <div className="details__count">{partners}</div>
            <div className="details__field">{totalPartnersString}</div>
          </div>

          <div className="details__block">
            <div className="details__count">{fCash(collected)}</div>
            <div className="details__field">Собрано</div>
          </div>
        </React.Fragment>
        }
      </div>
      <div className="project-progress__progress-bar progress-bar">
        <div className={wrapperClasses}>
          <div className={stageClasses} style={{ width: `${progressPercentage}%` }} />
        </div>
      </div>
    </div>
  )
}

export default ProjectProgress

ProjectProgress.propTypes = {
  partners: PropTypes.number.isRequired,
  projectData: PropTypes.object.isRequired,
  stageType: PropTypes.string.isRequired
}
