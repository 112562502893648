export const DEFAULT_PER_PAGE = 5
export const OPEN_PAYMENT_FORM = 'OPEN_PAYMENT_FORM'
export const CLOSE_PAYMENT_FORM = 'CLOSE_PAYMENT_FORM'

/**
 * Custom names for layout of project cards which contains stage types.
 * FUNDRAISING
 * INTEGRATION (or realization process: buying the materials, learning of team-members)
 * FINISHED (Integration and fundraising are done) and
 * @type {{INTEGRATION: string, FINISHED: string, FUNDRAISING: string}}
 */
export const STAGE_TYPES = {
  FUNDRAISING: 'fundraising',
  INTEGRATION: 'integration',
  FINISHED: 'finished'
}

export const getSectionName = (stageType) => {
  if (stageType === STAGE_TYPES.FUNDRAISING) {
    return 'Активные проекты'
  }

  if (stageType === STAGE_TYPES.INTEGRATION) {
    return 'Проекты в процессе реализации'
  }

  if (stageType === STAGE_TYPES.FINISHED) {
    return 'Реализованные проекты'
  }

  return null
}

export const STAGES = [
  {
    value: 'financing',
    label: 'Финансирование'
  },
  {
    value: 'purchasing',
    label: 'Закупка/реализация'
  },
  {
    value: 'installing',
    label: 'Открытие/презентация'
  },
  {
    value: 'training',
    label: 'Реализован/кейс'
  },
  {
    value: 'done',
    label: 'Проект требует масштабирования'
  }
]
