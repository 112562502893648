import { toast } from 'react-toastify'

import {
  MERCHANT_ID,
  BASE_REQUEST,
  BASE_CARD_PAYMENT_METHOD,
  CARD_PAYMENT_METHOD
} from '../../../constants/Api.constants'

export function getGoogleIsReadyToPayRequest () {
  return {
    ...BASE_REQUEST,
    allowedPaymentMethods: [ BASE_CARD_PAYMENT_METHOD ]
  }
}

function getGoogleTransactionInfo (totalPrice) {
  return {
    currencyCode: 'RUB',
    totalPriceStatus: 'FINAL',
    totalPrice: totalPrice
  }
}

function getGooglePaymentDataRequest (totalPrice) {
  return {
    ...BASE_REQUEST,
    allowedPaymentMethods: [ CARD_PAYMENT_METHOD ],
    transactionInfo: getGoogleTransactionInfo(totalPrice),
    merchantInfo: {
      merchantId: MERCHANT_ID
    }
  }
}

export function getGooglePaymentsClient () {
  let paymentsClient = null

  if (!window.paymentsClient) {
    paymentsClient = new window.google.payments.api.PaymentsClient({ environment: 'PRODUCTION' })
  }
  return paymentsClient
}

function processPayment (paymentData, totalPrice, callbackRequest) {
  const { token } = paymentData.paymentMethodData.tokenizationData
  callbackRequest(totalPrice, token, paymentData.email)
}

export function onGooglePaymentButtonClicked (callbackRequest) {
  const inputValue = document.getElementById('amount-field').value
  const paymentDataRequest = getGooglePaymentDataRequest(inputValue)

  const paymentsClient = getGooglePaymentsClient()
  paymentsClient.loadPaymentData(paymentDataRequest)
    .then(function (paymentData) {
      processPayment(paymentData, paymentDataRequest.transactionInfo.totalPrice, callbackRequest)
    })
    .catch(function (err) {
      toast.error(`${err.statusCode}. ${err.statusMessage}`)
    })

  if (typeof window !== 'undefined') {
    window.ym(88121258, 'reachGoal', 'card')
  }
}

function addGooglePayButton (callbackRequest) {
  const paymentsClient = getGooglePaymentsClient()
  const button = paymentsClient.createButton({
    onClick: () => onGooglePaymentButtonClicked(callbackRequest),
    buttonType: 'short',
    buttonColor: 'black'
  })
  document.getElementById('google-pay').innerHTML = ''
  document.getElementById('google-pay').appendChild(button)
}

export function onGooglePayLoaded (callbackRequest) {
  const paymentsClient = getGooglePaymentsClient()
  paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
    .then(function (response) {
      if (response.result) {
        addGooglePayButton(callbackRequest)
      }
    })
    .catch(function (err) {
      toast.error(`${err.statusCode}. ${err.statusMessage}`)
    })
}
