import React, { useState, useEffect } from 'react'
import { Formik, useFormikContext } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import { useHistory } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import {
  TextField,
  TextareaField,
  FiledropField,
  AutosuggestTextField
} from '../../components/Input'
import { Button } from '../../components/Button'
import { theme } from '../../layouts/registration/RegistrationForm.suggestionTheme'
import requestSuggestions from '../../layouts/registration/utils/dadata'

import {
  createProjectAction,
  uploadFileAction,
  removeFileAction
  // WIP createCategoryAction
} from './CreateProject.actions'
import {
  CREATE_PROJECT_FIELDS,
  ERROR_MESSAGES,
  getMediaFields,
  PROJECT_FIELDS,
  PROJECT_DIRECTION,
  PROJECT_DIRECTION_PLACEHOLDERS,
  INITIATOR_PROJECT,
  INITIATOR_PROJECT_PLACEHOLDERS,
  VALIDATION_SCHEMA, FIELDS_NAMES
} from './CreateProject.constants'

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorName = FIELDS_NAMES.find(error => keys.includes(error))
        const selector = `[name=${errorName}]`
        const errorElement = document.querySelector(selector)
        const parentErrorElement = errorElement.parentElement
        if (errorElement) {
          parentErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          parentErrorElement.focus({ preventScroll: true })
        }
      }
    }
  }, [ errors, isSubmitting, isValidating ])

  return null
}

const CreateProject = () => {
  const history = useHistory()
  const push = history.push
  const {
    CreateProject: {
      logo,
      isUploading,
      isUploadingError,
      isFetching,
      projectFiles,
      isFileUploading
    },
    auth
  } = useSelector(state => state)

  const dispatch = useDispatch()

  const [ linksCount ] = useState(0)
  const [ validationSchema, setValidationSchema ] = useState(VALIDATION_SCHEMA)

  useEffect(() => {
    setValidationSchema(validationSchema.shape(
      getMediaFields(linksCount)
    ))
    /* eslint-disable-next-line */
  }, [ linksCount ])

  const onSubmit = (values) => {
    // WIP values.request_category.trim().length !== 0 && dispatch(createCategoryAction(values.request_category))
    dispatch(createProjectAction({ ...values, logo, projectFiles, push, auth }))
  }

  // NEW FORM
  const [ suggestions, setSuggestions ] = useState([])
  const suggestionsArray = [ { title: 'Выберите вариант или продолжите ввод', suggestions } ]

  const onSuggestionsFetchRequested = ({ value }, type) => {
    if (type === PROJECT_FIELDS.FCS) {
      requestSuggestions('fio', value, setSuggestions, '')
    }

    if (type === PROJECT_FIELDS.EMAIL) {
      requestSuggestions('email', value, setSuggestions, '')
    }

    if (type === 'address') {
      requestSuggestions('address', value, setSuggestions, '')
    }
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestionValue = (suggestion) => suggestion.value

  const renderSuggestion = (suggestion, inputValue) => {
    const prefix = ''
    const ending = suggestion.value

    // if (suggestion.value.toLowerCase().indexOf(inputValue.toLowerCase()) === 0) {
    //   prefix = suggestion.value.slice(0, inputValue.length)
    //   ending = suggestion.value.slice(inputValue.length, suggestion.value.length)
    // }
    return <>
      <span style={{ color: 'blue' }}>{prefix}</span>{ending}
    </>
  }
  const renderSectionTitle = () => {
    return <div className="authorization-form__autosuggest-title"><p>Выберите вариант или продолжите ввод</p></div>
  }

  const getSectionSuggestions = (value) => {
    return value.suggestions
  }

  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid">
          <Formik
            initialValues={CREATE_PROJECT_FIELDS}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={values => onSubmit(values)}
          >
            {(form) => {
              const getTextareaField = (fieldData) => (
                <TextareaField
                  name={fieldData.name}
                  label={fieldData.label}
                  value={form.values[fieldData.name]}
                  error={form.errors[fieldData.name] && form.touched[fieldData.name] && form.errors[fieldData.name]}
                  onChange={event => form.setFieldValue(fieldData.name, event.target.value)}
                  onBlur={form.handleBlur}
                />
              )

              const getTextField = (fieldData) => (
                <TextField
                  name={fieldData.name}
                  label={fieldData.label}
                  value={form.values[fieldData.name]}
                  error={form.errors[fieldData.name] && form.touched[fieldData.name] && form.errors[fieldData.name]}
                  onChange={event => form.setFieldValue(fieldData.name, event.target.value)}
                  onBlur={form.handleBlur}
                />
              )

              const renderSuggestionFirstName = (suggestion) => {
                return renderSuggestion(suggestion, form.values.first_name)
              }

              const commonPropsInput = {
                medium: true,
                onChange: form.handleChange,
                onBlur: form.handleBlur,
                className: 'registration-form__input'
              }

              const inputPropsFCS = {
                ...commonPropsInput,
                name: PROJECT_FIELDS.FCS,
                label: 'ФИО*',
                value: form.values[PROJECT_FIELDS.FCS],
                error: form.errors[PROJECT_FIELDS.FCS] && form.touched[PROJECT_FIELDS.FCS] ? form.errors[PROJECT_FIELDS.FCS] : null
              }

              const inputPropsInitiatorFCS = {
                ...commonPropsInput,
                name: PROJECT_FIELDS.INITIATOR_FCS,
                label: 'ФИО*',
                value: form.values[PROJECT_FIELDS.INITIATOR_FCS],
                error: form.errors[PROJECT_FIELDS.INITIATOR_FCS] && form.touched[PROJECT_FIELDS.INITIATOR_FCS] ? form.errors[PROJECT_FIELDS.INITIATOR_FCS] : null
              }

              const inputPropsEmail = {
                ...commonPropsInput,
                type: 'mail',
                name: PROJECT_FIELDS.EMAIL,
                label: 'E-mail*',
                value: form.values[PROJECT_FIELDS.EMAIL],
                error: form.errors[PROJECT_FIELDS.EMAIL] && form.touched[PROJECT_FIELDS.EMAIL] ? form.errors[PROJECT_FIELDS.EMAIL] : null
              }

              const inputPropsLegalAddress = {
                ...commonPropsInput,
                name: PROJECT_FIELDS.LEGAL_ADDRESS,
                label: 'Юридический адрес*',
                value: form.values[PROJECT_FIELDS.LEGAL_ADDRESS],
                error: form.errors[PROJECT_FIELDS.LEGAL_ADDRESS] && form.touched[PROJECT_FIELDS.LEGAL_ADDRESS] ? form.errors[PROJECT_FIELDS.LEGAL_ADDRESS] : null
              }

              const inputPropsActualAddress = {
                ...commonPropsInput,
                name: PROJECT_FIELDS.ACTUAL_ADDRESS,
                label: 'Фактический адрес*',
                value: form.values[PROJECT_FIELDS.ACTUAL_ADDRESS],
                error: form.errors[PROJECT_FIELDS.ACTUAL_ADDRESS] && form.touched[PROJECT_FIELDS.ACTUAL_ADDRESS] ? form.errors[PROJECT_FIELDS.ACTUAL_ADDRESS] : null
              }

              const renderSuggestionEmail = (suggestion) => {
                return renderSuggestion(suggestion, form.values.email)
              }

              return (
                <form className="create-form" onSubmit={form.handleSubmit}>
                  <h1>Новый проект</h1>
                  <h3 className="margin-top-60">Контактная информация представителя организации -
                    инициатора проекта (не публикуется)</h3>
                  <Autosuggest
                    theme={theme}
                    suggestions={suggestionsArray}
                    onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, PROJECT_FIELDS.FCS)}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={(evt, { suggestion }) => {
                      form.setFieldValue(PROJECT_FIELDS.FCS, suggestion.value, true)
                    }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestionFirstName}
                    renderInputComponent={AutosuggestTextField}
                    inputProps={inputPropsFCS}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />
                  <TextField
                    mask="+7-999-999-99-99"
                    name={PROJECT_FIELDS.PHONE}
                    label="Мобильный телефон*"
                    value={form.values[PROJECT_FIELDS.PHONE]}
                    error={form.errors[PROJECT_FIELDS.PHONE] && form.touched[PROJECT_FIELDS.PHONE] && form.errors[PROJECT_FIELDS.PHONE]}
                    onChange={event => form.setFieldValue(PROJECT_FIELDS.PHONE, event.target.value)}
                    onBlur={form.handleBlur}
                  />

                  <Autosuggest
                    theme={theme}
                    suggestions={suggestionsArray}
                    onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, PROJECT_FIELDS.EMAIL)}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={(evt, { suggestion }) => {
                      form.setFieldValue(PROJECT_FIELDS.EMAIL, suggestion.value, true)
                    }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestionEmail}
                    renderInputComponent={AutosuggestTextField}
                    inputProps={inputPropsEmail}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />
                  {getTextField({ name: PROJECT_FIELDS.PROJECT_NAME, label: 'Название проекта*' })}

                  <h3 className="margin-top-60">Инициатор проекта</h3>

                  {getTextField({ name: PROJECT_FIELDS.INITIATOR_NAME, label: 'Наименование*' })}
                  {getTextareaField({ name: PROJECT_FIELDS.INITIATOR_DESCRIPTION, label: 'Описание*' })}

                  <Autosuggest
                    theme={theme}
                    suggestions={suggestionsArray}
                    onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, 'address')}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={(evt, { suggestion }) => {
                      form.setFieldValue(PROJECT_FIELDS.LEGAL_ADDRESS, suggestion.value, true)
                    }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestionEmail}
                    renderInputComponent={AutosuggestTextField}
                    inputProps={inputPropsLegalAddress}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />

                  <Autosuggest
                    theme={theme}
                    suggestions={suggestionsArray}
                    onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, 'address')}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={(evt, { suggestion }) => {
                      form.setFieldValue(PROJECT_FIELDS.ACTUAL_ADDRESS, suggestion.value, true)
                    }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestionEmail}
                    renderInputComponent={AutosuggestTextField}
                    inputProps={inputPropsActualAddress}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />

                  {Object.values(INITIATOR_PROJECT).map(item => getTextField({
                    name: item,
                    label: INITIATOR_PROJECT_PLACEHOLDERS[item]
                  }))}

                  <h3 className="margin-top-60">Логотип организации</h3>

                  <FiledropField
                    name={PROJECT_FIELDS.PROJECT_LOGO}
                    label="Перетащите логотип в эту область или кликните для загрузки*"
                    isMultiple={false}
                    isUploading={isUploading}
                    isShowHint={true}
                    value={form.values[PROJECT_FIELDS.PROJECT_LOGO]}
                    modifier="middle"
                    error={(form.errors[PROJECT_FIELDS.PROJECT_LOGO] && form.touched[PROJECT_FIELDS.PROJECT_LOGO]) || (isUploadingError && ERROR_MESSAGES.LOGO)}
                    onChange={(name, projectFiles, removedFileIndex = null) => {
                      form.setFieldValue(name, [ ...projectFiles ])
                      if (typeof removedFileIndex === 'number') {
                        dispatch(removeFileAction(removedFileIndex, name))
                      } else {
                        // eslint-disable-next-line array-callback-return
                        projectFiles.length !== 0 && projectFiles.forEach(image => {
                          dispatch(uploadFileAction([ image ], name))
                        })
                      }
                    }}
                    onBlur={form.handleBlur}
                  />

                  <div className="create-form__required-description">
                    <span>*Обязательное поле. Максимальный размер – 10 мб, 500 на 500 пикселей</span>
                  </div>

                  <h3 className="margin-top-60">Краткое описание проекта</h3>
                  {getTextareaField({ name: PROJECT_FIELDS.PROJECT_IDEA, label: 'Идея проекта*' })}
                  {getTextareaField({ name: PROJECT_FIELDS.PROJECT_UNIQUE, label: 'В чем его уникальность*' })}
                  {getTextareaField({ name: PROJECT_FIELDS.PROJECT_FEATURES, label: 'Особенности*' })}

                  <h3 className="margin-top-60">На что направлен проект</h3>
                  {Object.values(PROJECT_DIRECTION).map(item => getTextareaField({
                    name: item,
                    label: PROJECT_DIRECTION_PLACEHOLDERS[item]
                  }))}

                  <h3 className="margin-top-60">Описание аудитории, которой может быть интересна инициатива организации</h3>
                  {getTextareaField({ name: PROJECT_FIELDS.TARGET_AUDIENCE, label: 'Для кого создается проект*' })}
                  {getTextareaField({
                    name: PROJECT_FIELDS.TARGET_AUDIENCE_BENEFITS,
                    label: 'Чем именно проект может заинтересовать тех, для кого вы его создаете*'
                  })}

                  <h3 className="margin-top-60">Цитата о проекте от представителя организации</h3>
                  {getTextareaField({ name: PROJECT_FIELDS.QUOTE, label: 'Цитата*' })}

                  <h3 className="margin-top-60">ФИО представителя организации</h3>
                  <Autosuggest
                    theme={theme}
                    suggestions={suggestionsArray}
                    onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, PROJECT_FIELDS.FCS)}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={(evt, { suggestion }) => {
                      form.setFieldValue(PROJECT_FIELDS.INITIATOR_FCS, suggestion.value, true)
                    }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestionFirstName}
                    renderInputComponent={AutosuggestTextField}
                    inputProps={inputPropsInitiatorFCS}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />

                  <h3 className="margin-top-60">Должность представителя организации</h3>

                  <TextField
                    name={PROJECT_FIELDS.POSITION}
                    label="Должность*"
                    value={form.values[PROJECT_FIELDS.POSITION]}
                    error={form.errors[PROJECT_FIELDS.POSITION] && form.touched[PROJECT_FIELDS.POSITION] && form.errors[PROJECT_FIELDS.POSITION]}
                    onChange={event => form.setFieldValue(PROJECT_FIELDS.POSITION, event.target.value)}
                    onBlur={form.handleBlur}
                  />

                  <h3 className="margin-top-60">Фото представителя организации</h3>
                  <FiledropField
                    name={PROJECT_FIELDS.AGENT_PHOTO}
                    value={form.values[PROJECT_FIELDS.AGENT_PHOTO]}
                    modifier="middle"
                    error={form.touched[PROJECT_FIELDS.AGENT_PHOTO] && form.errors[PROJECT_FIELDS.AGENT_PHOTO]}
                    isUploading={isFileUploading}
                    isMultiple={false}
                    onChange={(name, projectFiles, removedFileIndex = null) => {
                      form.setFieldValue(name, [ ...projectFiles ])
                      if (typeof removedFileIndex === 'number') {
                        dispatch(removeFileAction(removedFileIndex, name))
                      } else {
                        // eslint-disable-next-line array-callback-return
                        projectFiles.length !== 0 && projectFiles.forEach(image => {
                          dispatch(uploadFileAction([ image ], name))
                        })
                      }
                    }}
                    onBlur={form.handleBlur}
                  />
                  <div className="create-form__required-description">
                    <span>Обязательное поле. Максимальный размер – 10 мб, 500 на 500 пикселей</span>
                  </div>

                  <h3 className="margin-top-60">Укажите тех, кому может быть интересно поддержать / профинансировать
                    проект</h3>
                  {getTextareaField({
                    name: PROJECT_FIELDS.FUND_SOURCES,
                    label: 'Укажите тех, кому может быть интересно поддержать / профинансировать проект*'
                  })}

                  <h3 className="margin-top-60">Укажите ваши собственные возможности для продвижения проекта</h3>
                  {getTextareaField({
                    name: PROJECT_FIELDS.PERSONAL_FUND_SOURCES,
                    label: 'Укажите ваши собственные возможности для продвижения проекта*'
                  })}

                  <h3 className="margin-top-60">Какие бонусы ваша организация может предложить партнерам (донорам)
                    проекта</h3>
                  {getTextareaField({
                    name: PROJECT_FIELDS.BONUSES,
                    label: 'Какие бонусы ваша организация может предложить партнерам (донорам) проекта*'
                  })}

                  <h3 className="margin-top-60">Фотоматериалы к проекту</h3>
                  <FiledropField
                    name={PROJECT_FIELDS.PROJECT_PHOTOS}
                    value={form.values[PROJECT_FIELDS.PROJECT_PHOTOS]}
                    modifier="middle"
                    error={form.touched[PROJECT_FIELDS.PROJECT_PHOTOS] && form.errors[PROJECT_FIELDS.PROJECT_PHOTOS]}
                    isUploading={isFileUploading}
                    onChange={(name, projectFiles, removedFileIndex = null) => {
                      form.setFieldValue(name, [ ...projectFiles ])
                      if (typeof removedFileIndex === 'number') {
                        dispatch(removeFileAction(removedFileIndex, name))
                      } else {
                        // eslint-disable-next-line array-callback-return
                        projectFiles.length !== 0 && projectFiles.forEach(image => {
                          dispatch(uploadFileAction([ image ], name))
                        })
                      }
                    }}
                    onBlur={form.handleBlur}
                  />
                  <div className="create-form__required-description">
                    <span>Обязательное поле. Максимальный размер – 10 мб.</span>
                  </div>

                  <h3 className="margin-top-60">Видеоматериалы к проекту</h3>
                  {getTextField({ name: PROJECT_FIELDS.PROJECT_VIDEO, label: 'Ссылка*' })}

                  <h3 className="margin-top-60">Смета проекта</h3>
                  <div className="create-form__hint">
                    <span>Шаблон <a href="/docs/estimate_template.xlsx" download>сметы проекта</a></span>
                  </div>
                  <FiledropField
                    name={PROJECT_FIELDS.PROJECT_ESTIMATE}
                    label="Перетащите файл в эту область или кликните для загрузки"
                    isMultiple={false}
                    value={form.values[PROJECT_FIELDS.PROJECT_ESTIMATE]}
                    modifier="middle"
                    error={form.touched[PROJECT_FIELDS.PROJECT_ESTIMATE] && form.errors[PROJECT_FIELDS.PROJECT_ESTIMATE]}
                    accept=""
                    icon="file"
                    isUploading={isFileUploading}
                    onChange={(name, projectFiles, removedFileIndex = null) => {
                      form.setFieldValue(name, [ ...projectFiles ])
                      if (typeof removedFileIndex === 'number') {
                        dispatch(removeFileAction(removedFileIndex, name))
                      } else {
                        // eslint-disable-next-line array-callback-return
                        projectFiles.length !== 0 && projectFiles.forEach(file => {
                          dispatch(uploadFileAction([ file ], name))
                        })
                      }
                    }}
                    onBlur={form.handleBlur}
                  />
                  <div className="create-form__required-description">
                    <span>Обязательное поле. Максимальный размер – 10 мб.</span>
                  </div>
                  {/* OLD FIELDS */}

                  <Button
                    type="submit"
                    width="267px"
                    height="72px"
                    tabIndex="14"
                    disable={isFetching}
                    color={isFetching ? 'gray' : 'blue'}
                    label="Создать проект"
                    large
                    className="margin-top-45"
                  />
                  <FocusError />
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </BaseLayout>
  )
}

export default CreateProject
