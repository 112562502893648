import React from 'react'

import BaseLayout from '../../layouts/BaseLayout'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import { QUESTIONS_DATA } from './Questions.constatns'

// WIP Format showing data. TODO: Integrate this page with server in the future.

const QuestionsPage = () => (
  <BaseLayout>
    <MetaTags
      title={META_TAGS_LIST.FAQ_TITLE}
      description={META_TAGS_LIST.FAG_DESCRIPTION}
    />
    <div className="container-app">
      <div className="container-fluid">
        <main className="questions__container">
          {QUESTIONS_DATA.map((data, index) => (
            <section key={`question_${index}`}>
              <h1>{data.question}</h1>
              <p>{data.answer}</p>

              {data.orderedList && <ol>
                {data.orderedList.map((orderedText, listIndex) =>
                  <li key={`ol-list-item_${listIndex}`}>
                    <div dangerouslySetInnerHTML={{ __html: orderedText }} />
                  </li>
                )}
              </ol>
              }

              {data.unOrderedList && <ul>
                {data.unOrderedList.map((unOrderedText, olIndex) =>
                  <li key={`ul-list_item_${olIndex}`}>
                    <p>{unOrderedText}</p>
                  </li>)
                }
              </ul>
              }
              {data.warning && <p>{data.warning}</p>}
            </section>
          ))}
        </main>
      </div>
    </div>
  </BaseLayout>
)

export default QuestionsPage
