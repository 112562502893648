/* In the future – this is will from the server. */

export const QUESTIONS_DATA = [
  {
    question: 'Какие проекты могут быть размещены на платформе #ПермьВдохновляет?',
    answer: 'Мы сотрудничаем с юридическими и физическими лицами, имеющими предпринимательские и общественные инициативы. Любая организация либо физическое лицо может стать инициатором проекта, способствующего более качественному, эффективному и интересному развитию нашего региона.'
  },
  {
    question: 'Как создать проект?',
    answer: 'Для начала работы по проекту возможны два варианта:',
    // TODO: Add Link component instead a-href tag.
    orderedList: [ '<p>Зарегистрируйтесь на сайте и оставьте заявку на <a href="/projects/new" target="_blank">странице создания проекта</a>, наши сотрудники свяжутся с вами для уточнения деталей и дальнейшего взаимодействия.</p>',
      '<p>Отправьте запрос на почту <a href="mailto:cf@deloros59.ru">cf@deloros59.ru</a> с кратким описанием проекта и указанием ваших контактных данных.</p>' ]
  },
  {
    question: 'Я принял участие в финансировании, где я могу увидеть результаты реализации проекта?',
    answer: 'После внесения платежа на указанный электронный адрес будет отправлена квитанция, подтверждающая произведенный платеж. Обновление информации на сайте о динамике сбора средств происходит не реже одного раза в неделю. \n' +
      'Динамика сбора средств и этапы реализации отображаются на странице соответствующего проекта. По итогам реализации проекта на платформе размещается информация и материалы с результатами реализации проекта.'
  },
  {
    question: 'На что пойдут мои деньги?',
    answer: 'Мы не являемся благотворительным фондом и не собираем деньги из сострадания, но привлекаем финансирование для креативных идей, которые решают вопросы развития региона. Результаты реализации конкретных проектов вы сможете увидеть в отчете на платформе.'
  },
  {
    question: 'Если нужная сумма не будет собрана, то проект не состоится?',
    answer: 'В случае неполного сбора средств от заявленной в проекте суммы может быть принято одно из следующих решений:',
    unOrderedList: [
      'продление срока сбора средств на проект;',
      'корректировка проекта под размер собранных средств;',
      'перенос финансирования на другой проект, размещенный на платформе.'
    ],
    warning: 'В таких случаях мы информируем доноров и предлагаем присоединиться к принятому решению. В случае несогласия с решением АНО «Платформа образовательных проектов» донор может запросить возврат переведенной суммы в соответствии с п. 3.7.2 Пользовательского соглашения.'
  },
  {
    question: 'Как я могу поддержать интересный мне проект?',
    answer: 'На данном этапе внесение средств от физических лиц возможно только через платежную систему платформы (кнопка поддержать проект) путем оплаты с банковской карты или через соответствующую форму на официальных страницах АНО «Платформа образовательных проектов» в социальных сетях. '
  },
  {
    question: 'В каком виде происходит реализация проектов?',
    answer: 'После сбора средств команда АНО «Платформа образовательных проектов» реализует заявленный проект «под ключ» и по договору передает результаты инициатору с оформлением необходимых документов.'
  },
  {
    question: 'А нельзя все сделать самим без платформы? ',
    answer: 'АНО «Платформа образовательных проектов» берет на себя реализацию заявленных проектов «под ключ», включая: несколько этапов оценки целесообразности, подтверждение оптимальности сметы проекта, закупку с доставкой до учреждения, юридические вопросы и контроль внедрения. Такой подход позволяет не только качественно и в срок реализовать лучшие предпринимательские и социально значимые инициативы граждан Пермского края, но и избежать нецелевое использование средств.'
  }
]
