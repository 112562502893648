import AlexPhoto from '../../ge/team/photo_team_axp.jpg'
import AnnaPhoto from '../../ge/team/photo_team_ap.jpg'
import XeniaPhoto from '../../ge/team/photo_team_ch_x.jpg'

export const WORK_INFO = [
  {
    id: '01',
    title: 'Размещение',
    text: 'Юридическое или физическое лицо размещают свой проект на платформе «#ПермьВдохновляет» с описанием того, что нужно для его реализации – сроки, стоимость и предполагаемые результаты. После этого наша команда проверяет и оценивает проект с помощью внешних экспертов.'
  },
  {
    id: '02',
    title: 'Продвижение',
    text: 'Продвижение, основанное на собственной технологии «#ПермьВдохновляет», позволяет нам максимально быстро и эффективно распространить информацию о проекте и привлечь необходимое финансирование.'
  },
  {
    id: '03',
    title: 'Реализация',
    text: '«#Пермьвдохновляет» совместно с инициаторами реализует отобранные проекты и оказывает информационную поддержку.\n' +
      'Важным условием нашей работы является обеспечение обратной связи и отчетность за потраченные средства партнерам проекта.\n'
  },
  {
    id: '04',
    title: 'Завершение',
    text: 'После реализации проекта «#ПермьВдохновляет» помогает оставаться на связи сообществу партнеров проекта и инициатора проекта, а также привлекает заинтересованных граждан для реализации новых инициатив.'
  }
]

export const ABOUT_PAGE_TEAM = [
  {
    id: 1,
    img: AlexPhoto,
    name: 'Алексей Павлов',
    text: 'Проект «ILoveSchool» - это наш вклад в интересное, разнообразное и успешное обучение в школах.',
    fullText: 'Проект «ILoveSchool» - это наш вклад в интересное, разнообразное и успешное обучение\n' +
      'в школах. Реализуя образовательные проекты вместе с партнерами и учителями, мы\n' +
      'делаем это с удовольствием и создаем отличные возможности для наших детей. Наш\n' +
      'проект, в первую очередь, направлен на реализацию инициатив в области развития\n' +
      'современных форм и методов образования, создание условий для использования в\n' +
      'учреждениях образования лучших, передовых практик. Наша цель – предложить удобный и\n' +
      'простой инструмент, который поможет тысячам образовательных учреждений по всей\n' +
      'стране получить необходимую поддержку и сделать образование детей современным и\n' +
      'успешным.'
  },
  {
    id: 2,
    img: AnnaPhoto,
    name: 'Анна Проценко',
    text: 'Что должно произойти, чтобы учителя и ученики в нашей стране имели возможность ' +
      'воплощать самые невероятные проекты?',
    fullText: 'Что должно произойти, чтобы учителя и ученики в нашей стране имели возможность\n' +
      'воплощать самые невероятные проекты? Где они могут взять деньги на это, как им\n' +
      'организовать процесс, как все оформить, как школе принять?.. Столько вопросов и ни\n' +
      'одного ответа! Проекты, как правило, угасают уже на стадии идеи просто по причине\n' +
      'глухоты и инертности системы образования ко всему новому и живому. У меня\n' +
      'педагогическое образование, я проработала в разных школах – от частных до\n' +
      'государственных – не один год и сама столкнулась с проблемой воплощения в жизнь по-\n' +
      'хорошему безумных и интересных идей. История обычно такая - сначала ты борешься,\n' +
      'потом ждешь чуда, а потом опускаешь руки. Вероятно, я из тех людей, которые склонны\n' +
      'делать что-то по той простой причине, что не могут найти причину этого не делать.\n' +
      'Поэтому мне доставляет невероятную радость, что «ILoveSchool» как раз решает все эти\n' +
      'вопросы и дает возможность реализовать свои идеи. Ведь мы предлагаем больше, чем\n' +
      'просто учиться. Не ограничивать себя тем, что умеешь и имеешь сейчас!'
  },
  {
    id: 5,
    img: XeniaPhoto,
    name: 'Ксения Чупракова',
    text: 'Воплощаем идеи и помогаем улучшать уровень школьного образования, для успешного будущего детей.',
    fullText: 'Воплощаем идеи и помогаем улучшать уровень школьного образования, для успешного будущего детей.  ' +
      'Дети – наше будущее, и нужно стараться дать все необходимые ресурсы и знания на этапе развития и социализации. ' +
      'И вместе с Вами мы можем реализовывать проекты, направленные на улучшения образовательного процесса. ' +
      'Ведь чтобы изменить мир, нужно начать с себя.'
  }
]
