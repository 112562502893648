import React, { useEffect, useState } from 'react'
import { Formik } from 'formik/dist/index'
import { connect, useDispatch } from 'react-redux'
import classNames from 'classnames'
import AutoSuggest from 'react-autosuggest'

import { TextField, AutosuggestTextField, CheckBoxField } from '../../components/Input'
import { Button } from '../../components/Button'
import { GenderIcon } from '../../components/GenderIcon/GenderIcon'
import { femaleIcons, maleIcons } from '../BaseLayout.constants'
import { closeRegistrationForm, openRegistrationSuccess } from '../BaseLayout.actions'
import { setSubscription } from '../../pages/Index/Index.actions'

import {
  REGISTRATION_FORM_INITIAL_VALUES,
  REGISTRATION_FORM_FIELDS
} from './RegistrationForm.constants'
import { registerUserAction } from './RegistrationForm.actions'
import { getRegistrationValidation } from './utils/validation'
import requestSuggestions from './utils/dadata'
import { theme } from './RegistrationForm.suggestionTheme'

const RegistrationForm = ({ closeRegistrationForm, registerUserAction, isFetching }) => {
  const [ currentGender, setCurrentGender ] = useState(null)
  const [ selectedIcon, setSelectedIcon ] = useState('')
  const [ isFormMount, setFormMount ] = useState(false)
  const [ avatarType, setAvatarType ] = useState(null)
  const [ suggestions, setSuggestions ] = useState([])
  const [ checkboxTouched, setCheckboxTouched ] = useState(false)

  const suggestionsArray = [ { title: 'Выберите вариант или продолжите ввод', suggestions } ]

  const formClassName = classNames({
    'authorization-form': true,
    'authorization-form--show': isFormMount
  })

  const dispatch = useDispatch()

  useEffect(() => {
    setFormMount(true)
  }, [])

  const onCloseForm = () => {
    setFormMount(false)
    setTimeout(closeRegistrationForm, 300)
  }

  const buttonMobile = window.innerWidth <= 320

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestionValue = (suggestion) => suggestion.value

  const onSuggestionsFetchRequested = ({ value }, type) => {
    if (type === 'first_name') {
      requestSuggestions('fio', value, setSuggestions, 'NAME')
    }

    if (type === 'last_name') {
      requestSuggestions('fio', value, setSuggestions, 'SURNAME')
    }

    if (type === 'email') {
      requestSuggestions('email', value, setSuggestions, '')
    }
  }

  const renderSectionTitle = () => {
    return <div className="authorization-form__autosuggest-title"><p >Выберите вариант или продолжите ввод</p></div>
  }

  const getSectionSuggestions = (value) => {
    return value.suggestions
  }

  const renderSuggestion = (suggestion, inputValue) => {
    let prefix = ''
    let ending = suggestion.value

    if (suggestion.value.toLowerCase().indexOf(inputValue.toLowerCase()) === 0) {
      prefix = suggestion.value.slice(0, inputValue.length)
      ending = suggestion.value.slice(inputValue.length, suggestion.value.length)
    }
    return <>
      <span style={{ color: 'blue' }}>{prefix}</span>{ending}
    </>
  }

  return (
    <div className={formClassName}>
      <div
        className="authorization-form__mask"
        onClick={() => onCloseForm()}
      />
      <div className="authorization-form__content">
        <div className="authorization-form__content-wrap text-center">
          <i
            className="icon icon--close authorization-form__close"
            onClick={() => onCloseForm()}
          />
          <h4 className="registration-form__title">Регистрация</h4>
          <Formik
            initialValues={REGISTRATION_FORM_INITIAL_VALUES}
            validationSchema={getRegistrationValidation()}
            onSubmit={(values) => {
              if (values.subscription_checkbox && values.email) {
                dispatch(setSubscription(values.email))
              }
              registerUserAction(values, currentGender, avatarType)
            }}
          >
            {(f) => {
              const commonPropsInput = {
                medium: true,
                onChange: f.handleChange,
                onBlur: f.handleBlur,
                className: 'registration-form__input'
              }

              const inputPropsFirstName = {
                ...commonPropsInput,
                tabIndex: '3',
                name: REGISTRATION_FORM_FIELDS.FIRST_NAME,
                label: 'Имя*',
                value: f.values.first_name,
                error: f.errors.first_name && f.touched.first_name ? f.errors.first_name : null
              }

              const inputPropsLastName = {
                ...commonPropsInput,
                tabIndex: '4',
                name: REGISTRATION_FORM_FIELDS.LAST_NAME,
                label: 'Фамилия*',
                value: f.values.last_name,
                error: f.errors.last_name && f.touched.last_name ? f.errors.last_name : null
              }

              const inputPropsEmail = {
                ...commonPropsInput,
                required: true,
                tabIndex: '5',
                type: 'mail',
                name: 'email',
                label: 'E-mail*',
                value: f.values.email,
                error: f.errors.email && f.touched.email ? f.errors.email : null
              }

              const renderSuggestionFirstName = (suggestion) => {
                return renderSuggestion(suggestion, f.values.first_name)
              }

              const renderSuggestionLastName = (suggestion) => {
                return renderSuggestion(suggestion, f.values.last_name)
              }

              const renderSuggestionEmail = (suggestion) => {
                return renderSuggestion(suggestion, f.values.email)
              }

              return (<form className="registration-form" onSubmit={f.handleSubmit}>
                <AutoSuggest
                  theme={theme}
                  suggestions={suggestionsArray}
                  onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, 'first_name')}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={(evt, { suggestion }) => {
                    f.setFieldValue('first_name', suggestion.value, true)
                  }}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestionFirstName}
                  renderInputComponent={AutosuggestTextField}
                  inputProps={inputPropsFirstName}
                  multiSection={true}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                />
                <AutoSuggest
                  theme={theme}
                  suggestions={suggestionsArray}
                  onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, 'last_name')}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={(evt, { suggestion }) => {
                    f.setFieldValue('last_name', suggestion.value, true)
                  }}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestionLastName}
                  renderInputComponent={AutosuggestTextField}
                  inputProps={inputPropsLastName}
                  multiSection={true}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                />
                <AutoSuggest
                  theme={theme}
                  suggestions={suggestionsArray}
                  onSuggestionsFetchRequested={(value) => onSuggestionsFetchRequested(value, 'email')}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={(evt, { suggestion }) => {
                    f.setFieldValue('email', suggestion.value, true)
                  }}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestionEmail}
                  renderInputComponent={AutosuggestTextField}
                  inputProps={inputPropsEmail}
                  multiSection={true}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                />
                <TextField
                  required
                  showPassword
                  medium
                  tabIndex="6"
                  type="password"
                  name={REGISTRATION_FORM_FIELDS.PASSWORD}
                  label="Пароль*"
                  value={f.values.password}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  className="registration-form__input"
                  error={f.errors.password && f.touched.password ? f.errors.password : null}
                />
                <TextField
                  showPassword
                  medium
                  tabIndex="7"
                  type="password"
                  name={REGISTRATION_FORM_FIELDS.CONFIRM_PASSWORD}
                  label="Повторите пароль*"
                  value={f.values.confirm_password}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  className="registration-form__input"
                  error={f.errors.confirm_password && f.touched.confirm_password ? f.errors.confirm_password : null}
                />
                <div className="authorization-form__gender">
                  {!buttonMobile && <div className="authorization-form__gender-buttons">
                    <Button
                      type="button"
                      className="btn--female"
                      label="Женщина"
                      color={currentGender === 'female' ? 'orange' : 'gray-light'}
                      nobg
                      nocaps
                      noborder
                      width={140}
                      onClick={() => setCurrentGender('female')}
                    />
                    <Button
                      type="button"
                      className="btn--male"
                      label="Мужчина"
                      color={currentGender === 'male' ? 'orange' : 'gray-light'}
                      nobg
                      nocaps
                      noborder
                      width={140}
                      onClick={() => setCurrentGender('male')}
                    />
                  </div>}
                  {buttonMobile &&
                  <div className="registration-form__gender-buttons">
                    <Button
                      type="button"
                      label="Женщина"
                      width="112px"
                      className="registration-form__gender-button__female"
                      color={currentGender === 'female' ? 'orange' : 'gray-light'}
                      onClick={() => setCurrentGender('female')}
                    />
                    <Button
                      type="button"
                      label="Мужчина"
                      width="112px"
                      className="registration-form__gender-button__male"
                      color={currentGender === 'male' ? 'orange' : 'gray-light'}
                      onClick={() => setCurrentGender('male')}
                    />
                  </div>}
                  {currentGender && <div className="registration-form__gender-title">Как будет выглядеть ваша аватарка?</div>}
                  <div className="authorization-form__gender-content">
                    {currentGender === 'female' && femaleIcons.map(item => (
                      <GenderIcon
                        key={item.id}
                        item={item}
                        onSelect={setSelectedIcon}
                        onChangeType={setAvatarType}
                        selectedIcon={selectedIcon}
                      />
                    ))
                    }
                    {currentGender === 'male' && maleIcons.map(item => (
                      <GenderIcon
                        key={item.id}
                        item={item}
                        onSelect={setSelectedIcon}
                        onChangeType={setAvatarType}
                        selectedIcon={selectedIcon}
                      />
                    ))
                    }
                  </div>
                </div>
                <div className="registration-form__checkboxes">
                  <h4 className="registration-form__checkboxes-title">Я ознакомился и согласен</h4>
                  <div className="registration-form__checkboxes-wrapper">
                    <CheckBoxField
                      id="registration-form__checkbox-subscription"
                      label={<span>c подпиской на рассылки и новости</span>}
                      onChange={(evt) => {
                        setCheckboxTouched(true)
                        f.handleChange(evt)
                      }}
                      value={f.values.subscription_checkbox}
                      onBlur={(evt) => {
                        setCheckboxTouched(true)
                        f.handleBlur(evt)
                      }}
                      remember
                      name={REGISTRATION_FORM_FIELDS.SUBSCRIPTION}
                      className="registration-form__checkboxes-item"
                    />
                    <CheckBoxField
                      id="registration-form__checkbox-offer"
                      label={<span>c<a className="subscribe-form__link" href="/docs/public_offer.pdf" target="_blank"> офертой</a></span>}
                      onChange={(evt) => {
                        setCheckboxTouched(true)
                        f.handleChange(evt)
                      }}
                      value={f.values.offer_checkbox}
                      onBlur={(evt) => {
                        setCheckboxTouched(true)
                        f.handleBlur(evt)
                      }}
                      remember
                      name={REGISTRATION_FORM_FIELDS.OFFER}
                      className="registration-form__checkboxes-item"
                      error={!f.values.offer_checkbox && (checkboxTouched || !!Object.keys(f.touched).length)}
                    />
                    <CheckBoxField
                      id="registration-form__checkbox-personal-data"
                      label={<span>c<a className="subscribe-form__link" href="/docs/personal_data.pdf" target="_blank"> политикой обработки ПД</a></span>}
                      onChange={(evt) => {
                        setCheckboxTouched(true)
                        f.handleChange(evt)
                      }}
                      value={f.values.personal_checkbox}
                      onBlur={(evt) => {
                        setCheckboxTouched(true)
                        f.handleBlur(evt)
                      }}
                      remember
                      name={REGISTRATION_FORM_FIELDS.PERSONAL_DATA}
                      className="registration-form__checkboxes-item"
                      error={!f.values.personal_checkbox && (checkboxTouched || !!Object.keys(f.touched).length)}
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  className="registration-form__button"
                  medium
                  label="Продолжить"
                  color={(!f.isValid || isFetching) ? 'gray' : 'orange'}
                  disabled={!f.isValid || isFetching}
                  width="100%"
                  onClick={() => {
                    window.ym(88121258, 'reachGoal', 'reg')
                  }}
                />
              </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  closeRegistrationForm: () => dispatch(closeRegistrationForm()),
  registerUserAction: (params, gender, avatarType) => dispatch(registerUserAction(params, gender, avatarType)),
  openRegistrationSuccess: () => dispatch(openRegistrationSuccess())
})

const mapStateToProps = ({ baseLayoutReducer }) => ({
  isFetching: baseLayoutReducer.isFetching
})
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)
