import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { onPaymentFormAction } from '../../pages/Project/Project.actions'
import { API_IMG } from '../../constants/Api.constants'
import noUserPicture from '../../ge/no-userpic.svg'
import { getPercentageByStage, getProjectStageType } from '../../pages/utils/projectStages'
import { STAGE_TYPES } from '../../pages/Project/constants'
import { fCash } from '../../helpers/formatters'
import { Button } from '../Button'
import { isServer } from '../../constants/Server.constants'

const ProjectCard = ({
  title,
  link,
  goal,
  stage,
  collected,
  modifier = null,
  className,
  logo,
  description,
  openPaymentForm,
  balance = null
}) => {
  const [ paymentButtonVisible, setPaymentButtonVisible ] = useState(false)
  const stageType = getProjectStageType(stage)
  const dispatch = useDispatch()
  const progressPercentage = getPercentageByStage(stageType, collected, goal, stage)
  let isDesktop = true
  if (!isServer) {
    isDesktop = document.documentElement.clientWidth >= 768
  }

  const progressClassNames = classNames('project-card__progress', {
    'project-card__progress--full': stageType === STAGE_TYPES.FINISHED,
    'project-card__progress--colored': stageType === STAGE_TYPES.INTEGRATION
  })

  const projectCardClassNames = classNames('project-card', {
    'project-card--large': modifier === 'large',
    [`${className}`]: className
  })

  const getClass = (name) => classNames(`project-card__${name}`, {
    [`project-card__${name}--large`]: modifier === 'large'
  })

  const progressClasses = classNames('project-card__progress-bar', {
    'project-card__progress-bar--colored': stageType === STAGE_TYPES.INTEGRATION
  })

  const onSendDonate = (event) => {
    event.preventDefault()
    openPaymentForm()
    dispatch(onPaymentFormAction(true))
  }

  return (
    <Link
      onMouseEnter={() => isDesktop && setPaymentButtonVisible(true)}
      onMouseLeave={() => isDesktop && setPaymentButtonVisible(false)}
      to={link} className={projectCardClassNames}
    >
      <div className="project-card__head-wrapper">
        <div className={getClass('cover')}>
          <div className="project-card__head-gradient" />

          <img
            alt="Main logo of project"
            className={(getClass('logo'))}
            src={logo ? `${API_IMG}/${logo.filename}` : noUserPicture}
          />

          <div className="project-card__participants-count">{title}</div>
        </div>
      </div>
      <div className="project-card__body-wrapper">
        <div className="project-card__body-head-wrapper">
          <div className={getClass('title')} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {paymentButtonVisible && (
          <Button
            label="Поддержать проект"
            className="project-card__footer-donate"
            onClick={onSendDonate}
          />
        )}
        {!paymentButtonVisible && (
          <div className="project-card__body-footer-wrapper">
            <div className={progressClasses}>
              <div className={progressClassNames} style={{ width: `${progressPercentage}%` }} />
            </div>
            <div className="project-card__financing-details">
              <div
                className="project-card__details-wrapper"
              >
                {stageType === STAGE_TYPES.FUNDRAISING &&
                <span>
                  Осталось собрать <span className="project-card__goal">{fCash(balance)}</span>
                </span>
                }
                {stageType === STAGE_TYPES.INTEGRATION &&
                <span>Реализация проекта</span>
                }
                {stageType === STAGE_TYPES.FINISHED &&
                <span>Проект реализован</span>
                }
              </div>
              <Button
                label="Поддержать"
                className="project-card__footer-donate project-card__footer-donate_mobile"
                onClick={onSendDonate}
              />
            </div>
          </div>
        )}
      </div>
    </Link>
  )
}

export default ProjectCard

ProjectCard.propTypes = {
  balance: PropTypes.number.isRequired,
  className: PropTypes.string,
  collected: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  logo: PropTypes.object,
  modifier: PropTypes.string,
  onClick: PropTypes.func,
  stage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string
}
