import { Link } from 'react-router-dom'
import React from 'react'

import imgBecomePartner1 from '../../ge/number-one.png'
import imgBecomePartner2 from '../../ge/number-two.png'
import imgBecomePartner3 from '../../ge/number-three.png'
export const BP_GUIDE_BLOCK = [
  {
    img: imgBecomePartner1,
    text: <span>Выберите <Link to="/schools">отрасль</Link> или <Link to="/projects">проект</Link> - по категории или названию</span>
  },
  {
    img: imgBecomePartner2,
    text: 'Примите участие в финансировании проекта'
  },
  {
    img: imgBecomePartner3,
    text: 'Следите за всеми этапами реализации проекта на нашей платформе'
  }
]

export const BP_TEST_BLOCK = [
  {
    stage: 'Публикация',
    text: 'Перед тем, как каждый проект инициатора будет опубликован и доступен для поиска на «#ПермьВдохновляет», мы внимательно его проверяем. Члены нашей команды совместно с экспертным советом смотрят, чтобы проект отвечал требованиям надежности, эффективности и приносил пользу Пермскому краю.'
  },
  {
    stage: 'Реализация',
    text: 'Реализацию всех проектов мы производим совместно с инициаторами проектов. При этом вы можете следить за каждым этапом их выполнения.'
  },
  {
    stage: 'Завершение',
    text: 'После завершения проекта предоставляем партнерам проекта обратную связь. Публикуем на нашей платформе отчет и фотографии о работе/реализации действующего проекта, а также отзывы участников'
  }
]
