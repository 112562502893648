
import loadReduxTypes from '../../helpers/loadReduxTypes'

const ABOUT_PAGE_VENDORS = loadReduxTypes('ABOUT_PAGE_VENDORS')
const ABOUT_PAGE_MEMBERS = loadReduxTypes('ABOUT_PAGE_MEMBERS')

const initialState = {
  aboutPageVendors: null,
  aboutPageMembers: null,
  isFetching: null,
  error: null
}

export default function aboutPageReducer (state = initialState, { type, payload }) {
  switch (type) {
  case ABOUT_PAGE_VENDORS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case ABOUT_PAGE_VENDORS.SUCCESS: {
    return {
      ...state,
      aboutPageVendors: payload,
      isFetching: false
    }
  }

  case ABOUT_PAGE_VENDORS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case ABOUT_PAGE_MEMBERS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case ABOUT_PAGE_MEMBERS.SUCCESS: {
    return {
      ...state,
      aboutPageMembers: payload,
      isFetching: false
    }
  }

  case ABOUT_PAGE_MEMBERS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }
  default: return state
  }
}
