import React from 'react'

import BaseLayout from '../../layouts/BaseLayout'
import imgBecomePartnerCover from '../../ge/become-partner/become-partner__cover.jpg'
import ActionSection from '../../components/ActionSection'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import { BP_GUIDE_BLOCK, BP_TEST_BLOCK } from './BecomePartner.constants'

const BecomePartnerPage = () => {
  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.PARTNER_INFO_TITLE}
        description={META_TAGS_LIST.PARTNER_INFO_DESCRIPTION}
      />
      <div className="become-partner row justify-content-center margin-bottom-45">
        <h1 className="become-partner__header col-auto">
          Стать партнером проекта
        </h1>
      </div>
      <div className="row justify-content-center">
        <div className="become-partner__cover col-auto">
          <img src={imgBecomePartnerCover} alt="Become Partner" className="become-partner__cover-image" />
          <div className="become-partner__cover-text">
            «#ПермьВдохновляет» - простой и быстрый способ стать партнером различных предпринимательских и общественных проектов в Пермском крае.
            <br /> От туризма до промышленности, от IT до благоустройства города - в сотрудничестве с вами мы реализуем идеи, которые дают новые возможности для развития комфортной городской среды.
            <br /> Чтобы поддержать проект и стать партнером инициатора, вам нужно сделать всего три шага:
          </div>
        </div>
      </div>
      {BP_GUIDE_BLOCK.map((item, index) => (
        <React.Fragment key={index}>
          <div className="row justify-content-center margin-top-90">
            <img src={item.img} alt={index} className="col-auto become-partner__number-icon" />
          </div>
          <div className="row justify-content-center margin-top-30">
            <div className="become-partner__item-text col-auto">
              {item.text}
            </div>
          </div>
        </React.Fragment>
      ))
      }
      <div className="row justify-content-center margin-top-90">
        <h2 className="become-partner__header col-auto">
          Как мы проверяем проекты, партнерами которых вы становитесь:
        </h2>
      </div>
      <div className="row justify-content-center margin-top-30">
        <div className="become-partner__stages col-auto">
          {BP_TEST_BLOCK.map((item, index) => (
            <div className="row become-partner__stage" key={index}>
              <div className="col-xl-4 col-xm-12">
                <div className="become-partner__stage-name">
                  {item.stage}
                </div>
              </div>
              <div className="become-partner__stage-text col-xl-8 col-xm-12">
                {item.text}
              </div>
            </div>
          ))}
          <div className="row become-partner__stage">
            <div className="col-xl-4 col-xm-12">
              <div className="become-partner__stage-name">Из чего складывается смета проекта</div>
            </div>
            <div className="become-partner__stage-text col-xl-8 col-xm-12">
              При расчете стоимости проекта учитываются следующие составляющие: средства, необходимые для реализации проекта; управление проектом; комиссия платежной системы; налоги.
            </div>
          </div>
        </div>
      </div>
      <div className="project-info__bottom-container">
        <div className="project-info__bottom-section">
          <ActionSection action="search" />
        </div>
      </div>
    </BaseLayout>
  )
}

export default BecomePartnerPage
