import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

// TODO: Check ESLint issue when remove comments (rule already sets)

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-app footer__block">
        <div className="footer__logo-block">
          <a href="https://deloros-perm.ru/" rel="noopener noreferrer" target="_blank" className="footer__logo-block-link" to="/" ></a>
        </div>

        <div className="footer__menu-1">
          <div className="footer__menu-title">
            О нас
          </div>
          <div className="footer__menu-items">
            <HashLink to="/about#team" className="footer__menu-link">Команда</HashLink>
            <Link to="/experts" className="footer__menu-link">Экспертный совет</Link>
            <Link to="/news" className="footer__menu-link">Новости</Link>
            <Link to="/documents" className="footer__menu-link">Документы</Link>
            <Link to="/faq" className="footer__menu-link">Частые вопросы</Link>
          </div>
        </div>
        <div className="footer__menu-2">
          <div className="footer__menu-title">
            Участники
          </div>
          <div className="footer__menu-items">
            <Link to="/partners" className="footer__menu-link">Партнеры</Link>
            <Link to="/schools" className="footer__menu-link">Инициаторы проектов</Link>
          </div>
        </div>
        <div className="footer__menu-3">
          <div className="footer__menu-title">
            Контакты
          </div>
          <ul className="footer__menu-list">
            <li className="footer__menu-item">
              <a href="mailto:cf@deloros59.ru" className="footer__menu-list-link">cf@deloros59.ru</a>
            </li>
            <li className="footer__menu-item">
              <a href="tel:8 800 555 2081" className="footer__menu-list-link">+7(342) 256-35-05</a>
            </li>
            <li className="footer__menu-item">ОГРН 1195958000424</li>
            <li className="footer__menu-item">ИНН 5902051740</li>
            <li className="footer__menu-item">КПП 590201001</li>
          </ul>
        </div>

        <div className="footer__social-block">
          {/* TODO: Remove background-image, ESLint-comments and add img-src */}
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            href="https://vk.com/deloros59"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-item footer__social-item--vk"
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            href="https://www.facebook.com/deloros59"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-item footer__social-item--fb"
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            href="https://www.instagram.com/deloros59/?hl=ru"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-item footer__social-item--ig"
          />
        </div>

        <div className="footer__bottom-block">
          <div className="footer__bottom-copyright">
            #ПермьВдохновляет © 2019-2021
          </div>
          <div className="footer__bottom-document">
            <Link to="/docs/public_offer.pdf" target="_blank">
              Публичная оферта
            </Link>
            <Link to="/docs/personal_data.pdf" target="_blank">
              Политика обработки персональных данных
            </Link>
            <Link to="/docs/user_agreement.pdf" target="_blank">
              Пользовательское соглашение
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
