import React from 'react'

import SuccessPicture from '../../../ge/payment/success_picture.svg'
import { Button } from '../../Button'

const SuccessForm = ({ setIsSuccessPaymentModal }) => {
  const onCloseModal = () => setIsSuccessPaymentModal(false)

  return (
    (
      <div className="payment-form">
        <div className="payment-form__wrapper">
          <div className="payment-form__close-icon" onClick={onCloseModal} />
          <img src={SuccessPicture} alt="Success message with peoples" />
          <div className="payment-form__success-message">
            <h1>Благодарим за поддержку!</h1>
          </div>
          <div>
            <Button
              type="submit"
              onClick={onCloseModal}
              label="К проекту"
              width="221px"
              height="56px"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default SuccessForm
