import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import validateCard from 'card-validator'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import cx from 'classnames'

import { CheckBoxField, TextField } from '../../Input/Input'
import Switch from '../../Switch/Switch'
import {
  NEW_PAYMENT_FORM_FIELDS,
  PAYMENT_FORM_SCHEMA,
  getPaymentInitialValues
} from '../constants'
import DonateSupport from '../../DonateSupport/DonateSupport'
import { saveAmountAction } from '../../../pages/Project/Project.actions'
import { createCryptogram, openSecure } from '../PaymentConfig/index'
import { Button } from '../../Button/Button'
import { formatMoney } from '../../../helpers/formatters'
import { onGooglePayLoaded } from '../PaymentConfig/googleConfig'
import { getServiceAmount, sendDonate, startAppleSession } from '../Payment.service'
import { setSubscription } from '../../../pages/Index/Index.actions'
import { APPLE_PAY_MERCHANT_ID } from '../../../constants/Api.constants'
import { TOAST_OPTIONS } from '../../../pages/Index/Index.constants'

const PaymentBankCardForm = ({
  amount,
  saleValues,
  projectID,
  setPaymentCheck,
  successCardPayment
}) => {
  const [ cardNumber, setCardNumber ] = useState()
  const [ activeSaleIndex, setActiveSaleIndex ] = useState(0)
  const [ formErrors, setFormErrors ] = useState()
  const [ isSubscribePayment, setIsSubscribePayment ] = useState(false)
  const [ hasApplePay, setApplePay ] = useState(false)
  const [ currentSale, setCurrentSale ] = useState(saleValues[activeSaleIndex])

  const dispatch = useDispatch()

  const cardType = validateCard.number(cardNumber).card?.type

  const onSubmit = (values) => {
    if (values.subscription && values.userEmail) {
      dispatch(setSubscription(values.userEmail))
    }

    const prepareData = Object.entries(values).map(item => ({
      attr: item[0],
      value: item[1]
    }))

    const summaryProjectAmount = values.amount
    const summaryServiceAmount = getServiceAmount(values.amount, currentSale)
    const summaryAmount = Math.round(values.amount * (currentSale / 100 + 1))

    createCryptogram(
      prepareData,
      values.userEmail,
      projectID,
      summaryAmount,
      summaryProjectAmount,
      summaryServiceAmount,
      setPaymentCheck,
      setFormErrors,
      isSubscribePayment,
      successCardPayment
    )
  }

  const getUrlImage = () => {
    if (cardType === 'visa') return '/images/visa.svg'
    if (cardType === 'mastercard') return '/images/mastercard.svg'
  }

  const onChangeAmount = (value) => {
    dispatch(saveAmountAction(value))
  }

  const onRightHandler = (toggleSwitch) => {
    toggleSwitch()
    setIsSubscribePayment(true)
  }

  const onLeftHandler = (toggleSwitch) => {
    toggleSwitch()
    setIsSubscribePayment(false)
  }

  const applePayHandler = (amount) => {
    const paymentRequest = {
      requiredShippingContactFields: [ 'email' ],
      countryCode: 'RU',
      currencyCode: 'RUB',
      supportedNetworks: [ 'visa', 'masterCard' ],
      merchantCapabilities: [ 'supports3DS' ],
      total: { label: '#ПермьВдохновляет', amount }
    }

    const sessionApplePay = new window.ApplePaySession(1, paymentRequest)

    sessionApplePay.onvalidatemerchant = (event) => {
      const requestData = {
        validation_url: event.validationURL
      }

      startAppleSession(requestData)
        .then((response) => {
          sessionApplePay.completeMerchantValidation(response.data.Model)
        })
        .catch(({ response }) => {
          toast(response.data.error, TOAST_OPTIONS)
        })
    }

    sessionApplePay.onpaymentauthorized = (event) => {
      const requestData = {
        cryptogram: JSON.stringify(event.payment.token),
        amount,
        service_fee: getServiceAmount(amount, currentSale),
        payment_type: 'one-time',
        email: event.payment.shippingContact.emailAddress
      }

      sendDonate(projectID, requestData, openSecure, setPaymentCheck, setFormErrors, successCardPayment, sessionApplePay)
    }

    sessionApplePay.begin()
  }

  const getClassButtons = (isDisable) => cx('donate-buttons', {
    'donate-buttons-disable': isDisable
  })

  useEffect(() => {
    setCurrentSale(saleValues[activeSaleIndex])
  }, [ activeSaleIndex ])

  useEffect(() => {
    onGooglePayLoaded((currentAmount, token, email) => sendDonate(projectID, {
      cryptogram: token,
      amount: currentAmount,
      service_fee: getServiceAmount(currentAmount, currentSale),
      payment_type: 'one-time',
      email
    }, openSecure, setPaymentCheck, setFormErrors))

    if (window.ApplePaySession) {
      const promisePayment = window.ApplePaySession.canMakePaymentsWithActiveCard(APPLE_PAY_MERCHANT_ID)
      promisePayment.then((canMakePayments) => {
        if (canMakePayments) {
          setApplePay(true)
        }
      })
    }
  }, [ setPaymentCheck, projectID, currentSale ])

  return (
    <Formik
      initialValues={{
        ...getPaymentInitialValues(amount),
        cardNumber: '',
        expDateYear: '',
        expDateMonth: '',
        cvv: ''
      }}
      validationSchema={PAYMENT_FORM_SCHEMA}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          touched,
          dirty,
          handleBlur,
          errors,
          setFieldValue,
          handleSubmit,
          setErrors,
          isValid
        }) =>
          <form id="payment-form" onSubmit={handleSubmit}>
            <div className="payment-form__payment-sum" >
              <div className="payment-form__amount-container">
                <TextField
                  id="amount-field"
                  name={NEW_PAYMENT_FORM_FIELDS.AMOUNT}
                  width={145}
                  className="payment-form__amount"
                  height={54}
                  align="left"
                  value={values.amount}
                  error={touched.amount && errors.amount}
                  onBlur={handleBlur}
                  onChange={event => {
                    onChangeAmount(event.target.value)
                    setFieldValue(NEW_PAYMENT_FORM_FIELDS.AMOUNT, event.target.value)
                  }}
                  isShowCurrency
                  currency="₽"
                  type="number"
                  noErrorText
                />
              </div>
              <div className="payment-form__validation-messages">
                <div>{touched.amount && errors.amount}</div>
              </div>

              <div className="payment-form__payment-details">
                <span>Минимальный платёж - 100 ₽</span>
              </div>
              <div className="payment-form-card payment-form-card--top">
                <Switch
                  leftText="Разовый"
                  rightText="Ежемесячный"
                  wrapperClass="switch-person"
                  onRightHandler={onRightHandler}
                  onLeftHandler={onLeftHandler}
                />
                <TextField
                  className="payment-form__email"
                  name="userEmail"
                  label="E-mail для получения чека и отчета"
                  error={touched.userEmail && errors.userEmail}
                  onBlur={handleBlur}
                  value={values.userEmail}
                  onChange={event => setFieldValue('userEmail', event.target.value)}
                />
              </div>
              <div className="payment-form__checkboxes">
                <h4 className="payment-form__checkboxes-title">Я ознакомился и согласен</h4>
                <div className="payment-form__checkboxes-wrapper">
                  <CheckBoxField
                    id="payment-form__checkbox-subscription"
                    label={<span>c подпиской на рассылки и новости</span>}
                    onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.SUBSCRIPTION, event.currentTarget.checked)}
                    remember
                    name={NEW_PAYMENT_FORM_FIELDS.SUBSCRIPTION}
                    className="payment-form__checkboxes-item"
                  />
                  <CheckBoxField
                    id="payment-form__checkbox-offer"
                    label={<span>c<a className="subscribe-form__link" href="/docs/public_offer.pdf" target="_blank"> офертой</a></span>}
                    onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.OFFER, event.currentTarget.checked)}
                    remember
                    name={NEW_PAYMENT_FORM_FIELDS.OFFER}
                    className="payment-form__checkboxes-item"
                    error={errors && errors[NEW_PAYMENT_FORM_FIELDS.OFFER]}
                  />
                  <CheckBoxField
                    id="payment-form__checkbox-personal-data"
                    label={<span>c<a className="subscribe-form__link" href="/docs/personal_data.pdf" target="_blank"> политикой обработки ПД</a></span>}
                    onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.PERSONAL_DATA, event.currentTarget.checked)}
                    remember
                    name={NEW_PAYMENT_FORM_FIELDS.PERSONAL_DATA}
                    className="payment-form__checkboxes-item"
                    error={errors && errors[NEW_PAYMENT_FORM_FIELDS.PERSONAL_DATA]}
                  />
                </div>
              </div>
            </div>
            {formErrors && (
              <div className="payment-form__validation-messages payment-form__validation-messages_big-font">
                {Object.values(formErrors).map(error => (
                  <div>{error}</div>
                ))}
              </div>
            )}
            <DonateSupport
              saleValues={saleValues}
              setActiveSaleIndex={setActiveSaleIndex}
              activeSaleIndex={activeSaleIndex}
            />
            <div className="payment-form__footer">
              <div className="payment-form__footer-content">
                <div className="payment-form__footer-summary">
                  <h2 className="payment-form__footer-header">Итого</h2>
                  <div className="payment-form__prices-wrapper">
                    <div className="payment-form__price">{formatMoney(values.amount * (saleValues[activeSaleIndex] / 100 + 1))}</div>
                  </div>
                </div>
                <div className="payment-form-card payment-form-card--bottom">
                  <div className="payment-form-card__number">
                    <TextField
                      mask="9999 9999 9999 9999"
                      dataCP="cardNumber"
                      name={NEW_PAYMENT_FORM_FIELDS.CARD_NUMBER}
                      label="Номер карты"
                      error={errors.cardNumber && touched.cardNumber && errors.cardNumber}
                      onBlur={handleBlur}
                      value={values.cardNumber}
                      onChange={event => {
                        setCardNumber(event.target.value)
                        setFieldValue(NEW_PAYMENT_FORM_FIELDS.CARD_NUMBER, event.target.value)
                      }}
                      noErrorText
                    />
                    <div className="card-type" style={{
                      backgroundImage: `url(${getUrlImage()})`
                    }} />
                  </div>
                  <div className="payment-form-card__date">
                    <TextField
                      name={NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_MONTH}
                      error={errors.expDateMonth && touched.expDateMonth && errors.expDateMonth}
                      mask="99"
                      label="мм"
                      onBlur={handleBlur}
                      value={values.expDateMonth}
                      onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_MONTH, event.target.value)}
                      noErrorText
                    />
                    <TextField
                      name={NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_YEAR}
                      error={errors.expDateYear && touched.expDateYear && errors.expDateYear}
                      onBlur={handleBlur}
                      mask="99"
                      label="гг"
                      value={values.expDateYear}
                      onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_YEAR, event.target.value)}
                      noErrorText
                    />
                    <TextField
                      name={NEW_PAYMENT_FORM_FIELDS.CVV}
                      error={errors.cvv && touched.cvv && errors.cvv}
                      onBlur={handleBlur}
                      mask="999"
                      label="CVC / CVV2"
                      value={values.cvv}
                      onChange={event => setFieldValue(NEW_PAYMENT_FORM_FIELDS.CVV, event.target.value)}
                      type="password"
                      showPassword
                      noErrorText
                    />
                  </div>
                </div>
                <div className={getClassButtons(!(values.offer && values['personal-data']))}>
                  {!(values.offer && values['personal-data']) && (
                    <div className="disable-block" onClick={() => {
                      setErrors({
                        [NEW_PAYMENT_FORM_FIELDS.PERSONAL_DATA]: 'isRequired',
                        [NEW_PAYMENT_FORM_FIELDS.OFFER]: 'isRequired'
                      })
                    }} />
                  )}
                  <div id="google-pay" />
                  <Button
                    className="card-payment__submit-button"
                    type="submit"
                    label="Оплатить картой"
                    disabled={(!isValid)}
                    color={(isValid && dirty) ? 'black' : 'black-gray'}
                    onClick={() => {
                      window.ym(88121258, 'reachGoal', 'card')
                    }}
                  />
                  {hasApplePay && <button onClick={() => applePayHandler(values.amount)} type="button" lang="ru" className="apple-pay" />}
                </div>
              </div>
            </div>
          </form>
      }
    </Formik>
  )
}

export default PaymentBankCardForm
