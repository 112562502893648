import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../Button'
import ActionCreateIcon from '../../ge/action-section__create.png'
import ActionSearchIcon from '../../ge/action-section__search.png'

const ActionSection = ({ action }) => {
  const history = useHistory()

  let mainText, description, buttonClick, icon, buttonColor, buttonLabel

  if (action === 'create') {
    mainText = 'У вас есть идея проекта?'
    description = 'Расскажите о ней'
    buttonLabel = 'Создать проект'
    buttonClick = () => history.push('/projects/new')
    buttonColor = 'orange'
    icon = <img src={ActionCreateIcon} alt="create" />
  }

  if (action === 'search') {
    mainText = 'Эти проекты ждут вашего внимания'
    description = 'Ознакомьтесь с ними'
    buttonLabel = 'Посмотреть проекты'
    buttonClick = () => history.push('/projects?type=all')
    buttonColor = 'blue'
    icon = <img src={ActionSearchIcon} alt="search" />
  }

  return (
    <div className="create-project">
      <div className="create-project__left-section">
        {icon}
      </div>
      <div className="create-project__container">
        <div className="create-project__right-section">
          <h2>{mainText}</h2>
          <p>{description}</p>
          <Button
            tabIndex="13"
            label={buttonLabel}
            color={buttonColor}
            className="create-project__right-button"
            onClick={buttonClick}
          />
        </div>
      </div>
    </div>
  )
}

export default ActionSection
