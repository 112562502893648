import loadTypes from '../../helpers/loadReduxTypes'

const CREATE_PROJECT = loadTypes('CREATE_PROJECT')
const UPLOAD_FILES = loadTypes('UPLOAD_FILES')
const REMOVE_FILE = loadTypes('REMOVE_FILE')

const initialState = {
  isFetching: false,
  isUploading: false,
  isUploadingError: false,
  error: null,
  projectFiles: {
    organization_logo: [],
    member_photo: [],
    photos: [],
    estimate_document: []
  },
  isFileUploading: false,
  isFileUploadingError: false
}

export default function createProject (state = initialState, { type, payload }) {
  switch (type) {
  case CREATE_PROJECT.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case CREATE_PROJECT.SUCCESS: {
    return {
      ...state,
      isFetching: false
    }
  }

  case CREATE_PROJECT.FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }
  }

  case UPLOAD_FILES.REQUEST: {
    return {
      ...state,
      isFileUploading: true
    }
  }

  case UPLOAD_FILES.SUCCESS: {
    return {
      ...state,
      isFileUploading: false,
      projectFiles: {
        ...state.projectFiles,
        [payload.fieldName]: [ ...state.projectFiles[payload.fieldName], payload.fileId ]
      }
    }
  }

  case UPLOAD_FILES.FAILURE: {
    return {
      ...state,
      isFileUploading: false,
      isFileUploadingError: false
    }
  }

  case REMOVE_FILE.REQUEST: {
    return {
      ...state,
      projectFiles: { ...state.projectFiles, [payload.fieldName]: state.projectFiles[payload.fieldName].filter((file, index) => index !== payload.fileIndex) }
    }
  }

  default:
    return state
  }
}
