import React, { createRef, Component, useState } from 'react'
import Select from 'react-select'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import InputMask from 'react-input-mask'

import imgCheckboxX from '../../ge/checkbox-x.svg'
import Loader from '../Loader'

export class SelectField extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange (value) {
    this.props.onChange(this.props.name, value)
  }

  handleBlur () {
    this.props.onBlur(this.props.name, true)
  }

  render () {
    const {
      tabIndex = '0',
      inline = false,
      id,
      options,
      className,
      error,
      noErrorText = false,
      placeholder = 'Выберите категорию...',
      prefix = '',
      value
    } = this.props

    const selectClassNames = classNames({
      'react-select__control--gray': prefix === 'gray',
      'react-select': true
    })

    return (
      <div className={`input ${inline ? 'input--inline' : ''} ${className}`}>
        {
          (error && !noErrorText) &&
            <div className="input__error">
              { error }
            </div>
        }
        <Select
          tabindex={tabIndex}
          id={id}
          options={options}
          className="react-select"
          onChange={this.handleChange}
          classNamePrefix={selectClassNames}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          styles={{ background: '#023950' }}
          value={value || null}
        />
      </div>
    )
  }
}

export const TextField = ({
  tabIndex = '0',
  name = 'name',
  label = '',
  type = 'text',
  value,
  error,
  noErrorText = false,
  width = '100%',
  height = '',
  currency = null,
  inline = false,
  align = 'left',
  className = '',
  onChange,
  /* WIP TODO: Check function onBlur on all of components and remove this mock. */
  onBlur = () => '',
  medium = false,
  isForgotVisible = false,
  showPassword = false,
  onChangePassword,
  fieldDescription = false,
  isCentered = false,
  mask,
  id
}) => {
  const [ isPasswordShow, setPasswordShow ] = useState(false)

  const inputClassNames = classNames({
    'input__typefield input__typefield--text': true,
    [`text-${align}`]: align,
    'input__typefield--error': error,
    'input__typefield--medium': medium,
    'input__typefield--centered': true
  })

  const fieldPasswordClassNames = classNames({
    'input__field-password': true,
    'input__field-password--show': isPasswordShow
  })

  const wrapperClassNames = classNames({
    'input__field': true,
    'input__field--centered': isCentered
  })

  const handleChangePassword = () => {
    onChangePassword()
  }

  return (
    <div className={`input ${inline ? 'input--inline' : ''} ${className}`}>
      <div className={wrapperClassNames}>
        {mask ? (
          <InputMask
            id={id}
            type={isPasswordShow && showPassword ? 'text' : type}
            mask={mask}
            maskChar={null}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            tabIndex={tabIndex}
            name={name}
            className={inputClassNames}
            style={{ width, height }}
            placeholder={label}>
            {(inputProps) => {
              return (<input
                {...inputProps}
              />)
            }}
          </InputMask>
        ) : (
          <input
            id={id}
            tabIndex={tabIndex}
            type={isPasswordShow && showPassword ? 'text' : type}
            name={name}
            className={inputClassNames}
            style={{ width, height }}
            placeholder={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        {
          (error && !noErrorText) &&
            <div className="input__error">
              { error }
            </div>
        }
        {currency && <span className="input__currency-symbol">{currency}</span>}
        {isForgotVisible && <p onClick={handleChangePassword} className="input__field-forgot">Забыли?</p>}
        {fieldDescription && value.length < 3 && <p onClick={handleChangePassword} className="input__field-description">Минимум 3 символа</p>}
        {showPassword && <div
          className={fieldPasswordClassNames}
          onClick={() => setPasswordShow(!isPasswordShow)}
        />}
      </div>
    </div>
  )
}

export const AutosuggestTextField = ({
  tabIndex = '0',
  name = 'name',
  label = '',
  type = 'text',
  value,
  error,
  noErrorText = false,
  width = '100%',
  height = '',
  inline = false,
  align = 'left',
  className = '',
  onChange,
  onBlur = () => '',
  medium = false,
  isForgotVisible = false,
  showPassword = false,
  fieldDescription = false,
  isCentered = false,
  id,
  ...restProps
}) => {
  const inputClassNames = classNames('input__typefield', 'input__typefield--text', 'input__typefield--centered', {
    [`text-${align}`]: align,
    'input__typefield--error': error,
    'input__typefield--medium': medium
  })

  const wrapperClassNames = classNames('input__field', {
    'input__field--centered': isCentered
  })

  const inputWrapperClassNames = classNames('input', className, {
    'input--inline': inline
  })

  return (
    <div className={inputWrapperClassNames}>
      <div className={wrapperClassNames}>
        <input
          id={id}
          tabIndex={tabIndex}
          type={'text'}
          name={name}
          className={inputClassNames}
          style={{ width, height }}
          placeholder={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...restProps}
        />
        {
          (!!error && !noErrorText) &&
            <div className="input__error">
              { error }
            </div>
        }
      </div>
    </div>
  )
}

export class TextareaField extends Component {
  render () {
    const {
      tabIndex = '0',
      name = 'name',
      label = '',
      value = '',
      error,
      noErrorText = false,
      onChange,
      onBlur = () => ''
    } = this.props

    return (
      <div className="input">
        <div className="input__field">
          <textarea
            tabIndex={tabIndex}
            name={name}
            className={`input__typefield
              input__typefield--textarea
              ${error ? 'input__typefield--error' : ''}`}
            placeholder={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
        {
          (error && !noErrorText) &&
          <div className="input__error">
            { error }
          </div>
        }
      </div>
    )
  }
}

export const FiledropField = ({
  tabIndex = '0',
  name = 'name',
  label = 'Перетащите изображения в эту область или кликните для загрузки',
  value,
  error,
  noErrorText = false,
  accept = 'image/*',
  onChange,
  onBlur,
  isMultiple = true,
  isUploading = false,
  modifier = 'middle',
  fileListDisplay = true,
  cnInput,
  cnRoot,
  icon = 'image'
}) => {
  const dropzoneRef = createRef()
  const openUploadDialog = (e) => {
    e.stopPropagation()

    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  const inputClassNames = classNames({
    [cnInput]: !!cnInput,
    'input__filedrop': !cnInput,
    'input__filedrop--error': error
  })

  const MAX_SIZE = 10485760

  const rootClassNames = classNames({
    [cnRoot]: !!cnRoot,
    'input': !cnRoot,
    'input--inline': true,
    'input--middle': modifier === 'middle'
  })

  return (
    <Dropzone
      ref={dropzoneRef}
      accept={accept}
      onDrop={acceptedFiles => {
        if (acceptedFiles.length !== 0) {
          onChange(name, acceptedFiles)
        }
      }}
      multiple={isMultiple}
      minSize={0}
      maxSize={MAX_SIZE}
    >
      {({ getRootProps, getInputProps, rejectedFiles }) => {
        return (
          <div {...getRootProps({ tabIndex, className: rootClassNames })}>
            {(error && !noErrorText) && <div className="input__error">{error}</div>}
            {rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_SIZE && !isMultiple &&
            <div className="input__error">
              Допустимый размер файла – 10 мб.
            </div>
            }

            {rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_SIZE && isMultiple &&
            <div className="input__error">
              Допустимый размер файлов – 10 мб. Некоторые файлы не загружены.
            </div>
            }

            <input {...getInputProps({ name })} onBlur={onBlur} />

            <div className={inputClassNames} onClick={(e) => !isUploading && openUploadDialog(e)}>
              <div className="input__loader-container">
                {isUploading ? <Loader isSmall /> : icon === 'image' ? <i className="icon icon--add-image" /> : <i className="icon icon--add-file" />}
              </div>
              {value.length === 0 && <span className="input__filedrop-placeholder">{label}</span>}
              <div className="input__filedrop-center">
                <div className="input__filedrop-flex">
                  {fileListDisplay && value.length > 0 && value.map((file, key) => (
                    file.type.includes('image')
                      ? <div key={`${file.name}${key}`} className="input__filedrop-item">
                        <img
                          src={URL.createObjectURL(file)}
                          alt="preview"
                          className="input__preview"
                        />
                        <span className="input__filedrop-image-remove"
                          onClick={(e) => {
                            e.stopPropagation()
                            const fileRemoved = value.filter(file => !Object.is(file, value[key]))
                            onChange(name, fileRemoved, value.indexOf(value[key]))
                          }}
                        >✕</span>
                      </div>
                      : <p key={key} className="input__filedrop-file">
                        {file.name}{'\u00A0'}
                        <span className="input__filedrop-file-remove"
                          onClick={(e) => {
                            e.stopPropagation()
                            const fileRemoved = value.filter(file => !Object.is(file, value[key]))

                            onChange(name, fileRemoved, value.indexOf(value[key]))
                          }}
                        >✕</span>
                      </p>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Dropzone>
  )
}

export class CheckboxField extends Component {
  render () {
    const {
      tabIndex = '0',
      id,
      name = 'name',
      value,
      values,
      error,
      label = '',
      type = 'check',
      onChange
    } = this.props

    return (
      <div className="input input--inline">
        <input
          tabIndex="-1"
          type="checkbox"
          id={id}
          checked={values.includes(value)}
          onChange={() => {
            if (values.includes(value)) {
              onChange(name, values.filter(v => v !== value))
            } else {
              onChange(name, values.concat(value))
            }
          }}
        />
        <label
          tabIndex={tabIndex}
          htmlFor={id}
          className={`input__checkbox-${type}
            ${error ? ` input__checkbox-${type}--error` : ''}`}
          role="checkbox"
          aria-checked={values.includes(value)}
          aria-labelledby={id}
        >
          { label }
          {
            (values.includes(value) && type === 'label') &&
              <img
                className="input__checkbox-label-x"
                src={imgCheckboxX}
                alt="X"
              />
          }
        </label>
      </div>
    )
  }
}

export class ErrorField extends Component {
  render () {
    const {
      error
    } = this.props

    return (
      <React.Fragment>
        {
          error &&
            <div className="input-error">
              { error }
            </div>
        }
      </React.Fragment>
    )
  }
}

export const CheckBoxField = ({ name, label, id, remember = false, cnLabel = '', cnInput = '', error, ...rest }) => {
  const subscribeFormClassNames = classNames({
    'subscribe-form__terms': true,
    'subscribe-form__remember-me': remember
  })

  return (
    <div className={subscribeFormClassNames}>
      <input className={cnInput} type="checkbox" id={id} name={name} {...rest} />
      <label className={cnLabel} htmlFor={id}>{label}</label>
      {error && <span className="error-checkbox">*</span>}
    </div>
  )
}

export const EmailField = ({ errors, ...rest }) => (
  <input errors={errors} placeholder="Введите email" {...rest} />
)
