import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'
import { formatArrayImages } from '../utils/getObjectFields'

const ABOUT_PAGE_VENDORS = loadReduxTypes('ABOUT_PAGE_VENDORS')
const ABOUT_PAGE_MEMBERS = loadReduxTypes('ABOUT_PAGE_MEMBERS')

function getAboutPageVendorsAction () {
  return function action (dispatch) {
    dispatch({ type: ABOUT_PAGE_VENDORS.REQUEST })

    agent.GET('/vendor', { 'q': [ 'for_about__eq__True' ] })
      .then((response) => {
        dispatch({
          type: ABOUT_PAGE_VENDORS.SUCCESS,
          payload: formatArrayImages(response.data.data.items)
        })
      })

      .catch((error) => {
        dispatch({
          type: ABOUT_PAGE_VENDORS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

function getAboutPageMembers () {
  return function action (dispatch) {
    dispatch({ type: ABOUT_PAGE_MEMBERS.REQUEST })

    agent.GET('/team-members')
      .then((response) => {
        dispatch({
          type: ABOUT_PAGE_MEMBERS.SUCCESS,
          payload: response.data
        })
      })

      .catch((error) => {
        dispatch({
          type: ABOUT_PAGE_MEMBERS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

export { getAboutPageVendorsAction, getAboutPageMembers }
