import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import { Slider } from '../../components/Slider'
import TeamCard from '../../components/TeamCard'
import Popup from '../../components/Popup/Popup'
import { PartnersLogoGrid } from '../../components/Partners'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import { API_IMG } from '../../constants/Api.constants'

import { WORK_INFO } from './About.constants'
import { getAboutPageMembers, getAboutPageVendorsAction } from './About.actions'

const AboutPage = () => {
  const [ isPopupVisible, setIsPopupVisible ] = useState(false)
  const [ content, setContent ] = useState(null)
  const popupRef = useRef(null)

  const dispatch = useDispatch()

  const { aboutPage: {
    aboutPageVendors,
    aboutPageMembers,
    isFetching
  } } = useSelector(state => state)

  useEffect(() => {
    if (popupRef) {
      isPopupVisible ? disableBodyScroll(popupRef.current) : clearAllBodyScrollLocks()
    }
  }, [ isPopupVisible ])

  useEffect(() => {
    dispatch(getAboutPageVendorsAction())
    dispatch(getAboutPageMembers())
  }, [ dispatch ])

  const handleShowPopup = (content) => {
    setIsPopupVisible(!isPopupVisible)
    setContent(content)
  }

  const vendors = (!isFetching && aboutPageVendors) ? aboutPageVendors.map(i => ({ logo: i.logo, link: i.link })) : []
  const members = (!isFetching && aboutPageMembers) ? aboutPageMembers.map(i => ({ id: i.order_value, name: i.name, text: i.short_quote, fullText: i.quote, img: API_IMG + i.photo })) : []

  const sliderSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: true
      }
    }
  ]

  return (
    <React.Fragment>
      <BaseLayout>
        <MetaTags
          title={META_TAGS_LIST.ABOUT_TITLE}
          description={META_TAGS_LIST.ABOUT_DESCRIPTION}
        />
        <div className="container-app--about">
          <div className="container-fluid">
            <div className="welcome-section">
              <div className="welcome-section__content">
                <h2 className="welcome-section__title">#ПЕРМЬВДОХНОВЛЯЕТ - это краудфандинговая платформа для реализации лучших социально значимых проектов региона, выдвигаемых инициативными гражданами, способствующих развитию Пермского края </h2>
              </div>
            </div>

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--blue">
                  <p className="about-card__text">На платформе #ПЕРМЬВДОХНОВЛЯЕТ предприниматели и активные жители Пермского края могут заявить широкой аудитории о своих проектах, а партнеры - выбрать и принять в них участие.</p>
                </div>
                <div className="platform-section__content about-section__content">
                  <div className="platform-section__column-left">
                    <p className="platform-section__title">Мы убеждены</p>
                  </div>
                  <div className="platform-section__column-right">
                    <p className="platform-section__text">что, создавая системный ресурс для размещения лучших проектов жителей региона, мы формируем в их сознании позитивный образ влияния на среду, в которой они живут.  </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cover-section cover-section__image-head" />

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--lilac align-items-center">
                  <p className="about-card__text">#ПЕРМЬВДОХНОВЛЯЕТ – удобный и простой инструмент, который уже сегодня делает реализацию и развитие проектов инициаторов более системным и доступным.</p>
                </div>
                <div className="platform-section__content about-section__content">
                  <div className="platform-section__column-left">
                    <p className="platform-section__title">#ПЕРМЬВДОХНОВЛЯЕТ</p>
                  </div>
                  <div className="platform-section__column-right">
                    <p className="platform-section__text">Активные и инициативные жители и предприниматели региона получат возможность и инструменты для реализации своих идей. Общественные пространства, креативная экономика, проекты для бизнеса – вот те направления, в которых мы планируем реализовывать совместно с партнерами и инициаторами проекты и идеи.</p>
                    <p className="platform-section__text">Помимо поиска и привлечения партнеров огромное значение для нас имеет прозрачность реализации проектов. Мы гарантируем отсутствие нецелевого использования средств - для этого команда «#ПЕРМЬВДОХНОВЛЯЕТ» берет на себя контроль всех этапов проектной деятельности.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cover-section cover-section__image-body" />

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--orange align-items-center">
                  <p className="about-card__text">Это удобное и простое решение для реализации социально значимых, предпринимательских и общественных проектов.</p>
                </div>
              </div>
            </div>

            <div className="work-section">
              <div className="work-section__container about-section__container">
                <h3 className="work-section__title about-section__title">Как это работает</h3>
                <div className="work-section__content row flex-wrap">
                  { WORK_INFO.map(item => (
                    <div
                      key={item.id}
                      className="work-section__item"
                    >
                      <p className="work-section__item-number color-orange-1">{item.id}</p>
                      <p className="work-section__item-title">{item.title}</p>
                      <p className="work-section__item-text">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="team-section about-section" id="team">
              <div className="team-section__container about-section__container">
                <h3 className="team-section__title about-section__title">Наша команда</h3>
                <div className="team-section__team-slider">
                  {!!members.length && (
                    <Slider
                      sliderId="team"
                      slidesToShow={2}
                      buttonsLight
                      className="team-slider"
                      classNameButton="team-slider__buttons"
                      responsive={sliderSettings}
                    >
                      {members.map(item => (
                        <TeamCard
                          member={item}
                          showPopup={handleShowPopup}
                          key={item.id}
                        />
                      ))
                      }
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            <div className="partners-section">
              <div className="partners-section about-section__container">
                <h3 className="partners-section__title about-section__title">Партнеры</h3>
                <div className="partners">
                  <PartnersLogoGrid
                    partners={vendors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
      {isPopupVisible && <Popup onClose={setIsPopupVisible} content={content} innerRef={popupRef} /> }
    </React.Fragment>
  )
}

export default AboutPage
