import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { PAYMENT_FORM_FIELDS, PAYMENT_FORM_PROJECT_INFO, VALIDATION_SCHEMA } from '../PaymentForm/constants'
import SuccessForm from '../PaymentForm/stages/SuccessForm'
import { TextField } from '../Input'
import { Button } from '../Button'
import PaymentForm from '../PaymentForm/PaymentForm'
import { onPaymentFormAction } from '../../pages/Project/Project.actions'
import ModalContainer from '../ModalContainer/ModalContainer'

const ProjectPayment = ({ data }) => {
  const location = useLocation()
  const [ amount, setAmount ] = useState(null)
  const [ isSuccessPaymentModal, setIsSuccessPaymentModal ] = useState(false)

  const { isPaymentFormVisible } = useSelector(state => state.project)
  const dispatch = useDispatch()

  useEffect(() => {
    const pluginMode = new URLSearchParams(location.search).get('plugin')
    pluginMode === 'show' && dispatch(onPaymentFormAction(true))
  }, [ location.search, dispatch ])

  const { collected, goal, stage, id } = data

  const isDoneStage = stage === 'done'
  const isFinalStage = collected >= goal

  const successCardPayment = () => {
    window.ym(88121258, 'reachGoal', 'payment')

    dispatch(onPaymentFormAction(false))
    setIsSuccessPaymentModal(true)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const status = params.get('status')
    if (status) {
      params.get('status') === 'pay'
        ? setIsSuccessPaymentModal(true)
        : toast.error('Недостаточно средств на карте')
    }
  }, [])

  return (
    <React.Fragment>
      {isPaymentFormVisible &&
      <ModalContainer>
        <PaymentForm
          onClose={onPaymentFormAction}
          title={data.title}
          amount={amount}
          projectID={id}
          successCardPayment={() => successCardPayment()}
        />
      </ModalContainer>
      }
      {isSuccessPaymentModal && (
        <ModalContainer>
          <SuccessForm
            setIsSuccessPaymentModal={setIsSuccessPaymentModal}
          />
        </ModalContainer>
      )}

      <div className="d-md-none d-lg-block col-lg-4 project-info-payment">
        {isFinalStage && !isDoneStage && <div className="project-info-payment__realized-status">
          <span className="project-info-payment__primary-text">Ура!</span>&nbsp;Проект собрал нужную сумму
        </div>}

        {isDoneStage && <div className="project-info-payment__realized-status project-info-payment__realized-status--done">
          <span className="project-info-payment__primary-text">Спасибо за участие!</span>
        </div>}

        <div>
          <Formik
            isInitialValid
            validationSchema={VALIDATION_SCHEMA}
            initialValues={PAYMENT_FORM_PROJECT_INFO}
            onSubmit={(value) => {
              setAmount(value.amount)
              dispatch(onPaymentFormAction(!isPaymentFormVisible))
            }}>

            {(f) => {
              return (
                <form className="info-block__project-info-payment project-info-payment" onSubmit={f.handleSubmit}>
                  <TextField
                    className="project-info-payment__input"
                    name={PAYMENT_FORM_FIELDS.AMOUNT}
                    height="56px"
                    width="246px"
                    align="left"
                    label="₽  100"
                    value={f.values.amount}
                    error={f.errors.amount && f.touched.amount && f.errors.amount}
                    onBlur={f.handleBlur}
                    onChange={event => f.setFieldValue('amount', event.target.value)}
                    isCentered
                    noErrorText
                  />
                  <Button
                    className="project-info-payment__button"
                    type="submit"
                    label="Поддержать проект"
                    width="199px"
                    height="56px"
                    disabled={(!f.isValid)}
                    color={(!f.isValid) ? 'gray' : 'blue'}
                    onClick={() => {
                      window.ym(88121258, 'reachGoal', 'project-info')
                    }}
                  />
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectPayment
