import React from 'react'

import { Button } from '../../components/Button'
import imgQuotes from '../../ge/about/team-slide-q.svg'

const TeamCard = ({ member, showPopup }) => {
  return (
    <div className='team-slider__slide team-card'>
      <div className='team-card__content'>
        <div className='team-card__column-left'>
          <img
            alt={`Team member ${member.name}`}
            className='team-card__img'
            src={member.img}
          />
        </div>
        <div className="team-card__column-right">
          <div className='team-card__quote'>
            <img src={imgQuotes} alt='Quotes' />
            <p className='team-card__text'>{member.text}</p>
          </div>

          <p className='team-card__name'>{member.name}</p>
          <Button
            className='team-card__button'
            tabIndex='13'
            label={<span className="team-card__button-text">
              Читать полностью
              <span className="team-card__button-arrow" />
            </span>}
            color='orange'
            medium
            onClick={() => showPopup(member)}
            width=''
          />
        </div>
      </div>
    </div>
  )
}

export default TeamCard
