import React from 'react'

const Popup = ({ onClose, content, innerRef }) => (
  <div className="modal" ref={innerRef}>
    <div className="modal-content">
      <div
        className="modal-content__close-icon"
        onClick={() => onClose(false)}
      />
      <div className="modal-content__quotes-icon" />
      <div className="modal-content__body">
        {content.fullText}
      </div>
      <div className="modal-content__author">
        {content.name}
      </div>
    </div>
  </div>
)

export default Popup
