import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'

const EstimateTable = ({ estimate }) => {
  const [ isShowMore, setIsShowMore ] = useState(false)
  const [ tableHeight, setTableHeight ] = useState(0)
  const tableRef = useRef(null)

  const isShowMoreContainer = tableRef.current && tableRef.current.scrollHeight > 225

  useEffect(() => {
    setTableHeight(tableRef.current && tableRef.current.scrollHeight)
  }, [])

  return (
    <div className="project__text-container">
      <h4 className="project__sub-title">Куда будут направлены деньги</h4>
      <div className="project__table-container" style={{ height: isShowMore && tableHeight }}>
        <div
          className="project__estimate-table estimate-table"
          ref={tableRef}
          dangerouslySetInnerHTML={{ __html: estimate }}
        />
      </div>

      {isShowMoreContainer && <div className="estimate-table__bottom-container">
        <span className="estimate-table__more-button" onClick={() => setIsShowMore(!isShowMore)}>
          {isShowMore ? 'Скрыть детали' : 'Показать детали'}
        </span>
      </div>}
    </div>
  )
}

export default EstimateTable

/* Example for estimate prop. This field Should contain be
    <table border="0" cellpadding="0" cellspacing="0" dir="ltr">
      <tbody>
        <tr>
          <td>Наименование</td>
          <td>Количество</td>
          <td>Стоимость, руб.</td>
        </tr>
        <tr>
          <td>Парты Summa фирма isku</td>
          <td>40</td>
          <td>Парты Summa фирма isku руб.</td>
        </tr>
        <tr> <..only 3 td..> </tr>
       </tbody>
     </table>
*/

EstimateTable.propTypes = {
  estimate: PropTypes.node
}
