import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session'
import { routerReducer } from 'react-router-redux'

import projects from '../pages/SearchProjects/SearchProject.reducer'
import indexProjects from '../pages/Index/Index.reducer'
import schoolReducer from '../pages/School/School.reducer'
import newsReducer from '../pages/News/News.reducer'
import newsArticleReducer from '../pages/NewsArticle/NewsArticle.reducer'
import baseLayoutReducer from '../layouts/BaseLayout.reducer'
import auth from '../layouts/authorization/Authorization.reducer'
import CreateProject from '../pages/CreateProject/CreateProject.reducer'
import projectReducer from '../pages/Project/Project.reducer'
import signupCompleteReducer from '../pages/SignupComplete/SignupComplete.reducer'
import aboutPageReducer from '../pages/About/About.reducer'
import schoolsReducer from '../pages/Schools/Schools.reducer'
import profileReducer from '../pages/Profile/Profile.reducer'
import schoolProjectsReducer from '../pages/SchoolProjects/SchoolProjects.reducer'
import expertsReducer from '../pages/ExpertAdvice/ExpertAdvice.reducer'
import partnersReducer from '../pages/Partners/Partners.reducer'

const rootReducer = combineReducers({
  Session: sessionReducer,
  routing: routerReducer,
  projects,
  indexProjects,
  school: schoolReducer,
  schoolProjects: schoolProjectsReducer,
  news: newsReducer,
  newsArticle: newsArticleReducer,
  baseLayoutReducer,
  auth,
  CreateProject,
  project: projectReducer,
  signupComplete: signupCompleteReducer,
  aboutPage: aboutPageReducer,
  profile: profileReducer,
  schools: schoolsReducer,
  experts: expertsReducer,
  partners: partnersReducer
})

export default rootReducer
