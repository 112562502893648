import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { FacebookShareButton, VKShareButton } from 'react-share'

import imgVK from '../../ge/social-vk-hover.svg'
import imgFB from '../../ge/social-fb-hover.svg'
import { HOSTNAME_URL } from '../../constants/Api.constants'

const SharingButtons = ({ title, match: { url } }) => {
  return (
    <React.Fragment>
      <div>
        <div className="sharing-container">
          <VKShareButton title={title} url={`${HOSTNAME_URL}${url}`}>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={imgVK} alt="VKShare" className="news-article__social-content-img" />
            </a>
          </VKShareButton>

          <FacebookShareButton quote={title} url={`${HOSTNAME_URL}${url}`} >
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={imgFB} alt="FBShare" className="news-article__social-content-img" />
            </a>
          </FacebookShareButton>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SharingButtons)

SharingButtons.propTypes = {
  title: PropTypes.string.isRequired
}
