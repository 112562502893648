export const DOCUMENTS = [
  {
    id: 1,
    img: '/images/docs/ils_свидетельство-1.png',
    alt: 'АНО Платформа ОП (ИНН+ОГРН)',
    title: 'АНО Платформа ОП (ИНН+ОГРН)',
    download: 'АНО_Платформа_ОП_(ИНН+ОГРН).pdf'
  },
  {
    id: 2,
    img: '/images/docs/ils_свидетельство-2.png',
    alt: 'АНО Платформа ОП (МИНЮСТ)',
    title: 'АНО Платформа ОП (МИНЮСТ)',
    download: 'АНО_Платформа_ОП_(МИНЮСТ).pdf'
  },
  {
    id: 3,
    img: '/images/docs/ils_устав.png',
    alt: 'АНО Платформа ОП (УСТАВ)',
    title: 'АНО Платформа ОП (УСТАВ)',
    download: 'АНО_Платформа_ОП_(УСТАВ).pdf'
  },
  {
    id: 4,
    img: '/images/docs/Выписка_из_ЕГРЮЛ.png',
    alt: 'Выписка из ЕГРЮЛ',
    title: 'Выписка из ЕГРЮЛ',
    download: 'Выписка_из_ЕГРЮЛ.pdf'
  },
  {
    id: 5,
    img: '/images/docs/Реквизиты.png',
    alt: 'АНО Платформа ОП (РЕКВИЗИТЫ)',
    title: 'АНО Платформа ОП (РЕКВИЗИТЫ)',
    download: 'Реквизиты_компании.pdf'
  }
]
