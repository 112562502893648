import loadReduxTypes from '../../helpers/loadReduxTypes'

export const GET_ACTIVE_PROJECTS_SUCCESS = 'GET_ACTIVE_PROJECTS_SUCCESS'
export const GET_ACTIVE_PROJECTS_FAIL = 'GET_ACTIVE_PROJECTS_FAIL'
export const GET_ACTIVE_PROJECTS_REQUEST = 'GET_ACTIVE_PROJECTS_REQUEST'

export const GET_COMPLETED_PROJECTS_SUCCESS = 'GET_COMPLETED_PROJECTS_SUCCESS'
export const GET_COMPLETED_PROJECTS_FAIL = 'GET_COMPLETED_PROJECTS_FAIL'
export const GET_COMPLETED_PROJECTS_REQUEST = 'GET_COMPLETED_PROJECTS_REQUEST'

export const REALISATION_PROJECTS = loadReduxTypes('REALISATION_PROJECTS')

export const SUBSCRIBE_FORM_REQUEST = 'SUBSCRIBE_FORM_REQUEST'
export const SUBSCRIBE_FORM_FAIL = 'SUBSCRIBE_FORM_FAIL'
export const SUBSCRIBE_FORM_SUCCESS = 'SUBSCRIBE_FORM_SUCCESS'

export const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 3500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

export const PLACEHOLDER_SEARCH_PHONE = 'Напишите инициатора или название проекта'
export const PLACEHOLDER_SEARCH_DESKTOP = 'Напишите инициатора или название проекта'

export const PLACEHOLDER_SUBSCRIPTION_FORM_PHONE = 'Подпишитесь на рассылку'
export const PLACEHOLDER_SUBSCRIPTION_FORM_DESKTOP = 'Подпишитесь на нашу рассылку'
